.teams-section {
  padding-top: ac(99px, 40px);
  padding-bottom: ac(99px, 40px);
  --sw-margin: ac(99px, 32px);
  overflow: visible;

  &__decor {
    &:nth-child(1) {
      --arw: 1921;
      --arh: 999;
      top: ac(-63px, -30px);
      left: ac(-339px, -100px);
      width: 154.67%;
      min-width: 1000px;
      max-width: 1960px;
      position: absolute;
      z-index: -2;

      @media (min-width: 1900px) {
        left: -350px;
      }
    }

    &:nth-child(2) {
      --arw: 1419.45;
      --arh: 333;
      bottom: ac(-200px, -80px);
      left: ac(742px, 400px);
      width: 114.29%;
      max-width: 1420px;
      min-width: 600px;
      position: absolute;
      z-index: -1;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      position: absolute;
      bottom: ac(-59px, -40px);
      right: ac(-121px, -90px);
      z-index: -1;

      .aura {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(17, 246, 238, 0.4) 0%,
          rgba(225, 17, 253, 0) 100%
        );
      }
    }
  }

  &__title {
    @media (min-width: 641px) {
      padding-right: 55px;
    }
    @media (max-width: 440px) {
      font-size: ac(32px, 29px, 374, 440);
    }
    @media (max-width: 374px) {
      font-size: 25px;
    }

    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    position: relative;

    .swiper {
      max-width: 100%;
      width: 100%;
      overflow: visible;

      @media (max-width: 551px) {
        margin-left: 0;
        max-width: 330px;
      }
    }

    .swiper-buttons {
      @media (min-width: 641px) {
        position: absolute;
        right: 0;
        top: calc(var(--sw-margin) * -1);
        transform: translateY(-100%);

        .swiper-button {
          margin-top: 0;
          &::before {
            font-size: ac(21px, 19px);
          }
        }
      }
    }
  }

  &.home-page {
    margin-bottom: ac(90px, 32px);
    .teams-section {
      &__decor {
        &:nth-child(1) {
          top: ac(-312px, -30px);
        }
      }
    }
  }
}
