.our-services-section {
  --sw-margin: ac(60px, 32px);
  overflow: visible;
  padding-top: ac(99px, 40px);
  padding-bottom: ac(99px, 40px);

  .aura-container {
    position: absolute;
    top: ac(-400px, -100px);
    right: ac(-480px, -200px);
    z-index: -1;
    .aura {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(17, 246, 238, 0.3) 0%,
        rgba(17, 246, 238, 0) 100%
      );
    }
  }

  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    position: relative;

    .swiper {
      max-width: 100%;
      width: 100%;
      overflow: visible;

      @media (max-width: 640px) {
        margin-left: 0;
        max-width: 450px;
      }
    }

    .swiper-buttons {
      @media (min-width: 641px) {
        position: absolute;
        right: 0;
        top: calc(var(--sw-margin) * -1);
        transform: translateY(-100%);

        .swiper-button {
          margin-top: 0;
        }
      }
    }
  }

  &__item {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: ac(20px, 16px);
    padding: ac(30px, 24px) ac(30px, 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: ac(455px, 320px);
  }

  &__item-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(26px, 16px);

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__item-image {
    --arw: 1;
    --arh: 1;
    background: rgba(8, 7, 18, 0.5);
    border-radius: ac(12px, 10px);
    width: ac(96px, 64px);
    min-width: ac(96px, 64px);

    img {
      object-fit: contain;
    }
  }

  &__item-text {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__item-btn {
    margin-top: auto;
    color: var(--cl-white);

    i {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
