.blog-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid var(--cl-blue);
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  background: var(--cl-white);

  &__tag {
    position: absolute;
    top: ac(32px, 12px);
    left: ac(32px, 12px);
    color: var(--cl-black);
    background: rgba(255, 255, 255, 0.6);
    font-size: ac(15px, 13px);
    font-weight: 600;
    line-height: 1.27;
    font-family: var(--font-second);
    text-transform: uppercase;
    padding: 6px 16px;
    border-radius: 10px;
    min-height: ac(42px, 36px);
    max-width: calc(100% - 64px);
    transition: 0.3s;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__btn {
    position: absolute;
    top: ac(32px, 12px);
    right: ac(32px, 12px);
    --size: ac(50px, 36px);
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-white);
    border: var(--cl-white);
    border-radius: 10px;
    transition: 0.3s;
    z-index: 5;

    i {
      font-size: 19px;
      color: var(--cl-black);
    }

    &:hover {
      border-color: var(--cl-blue);
      background-color: var(--cl-pink);
    }
  }

  &__image {
    --arw: 606.99;
    --arh: 371;
    width: 100%;

    img {
      transition: 0.3s;
    }

    @media (max-width: 551px) {
      --arw: 328;
      --arh: 329;
    }
  }

  &__icon {
    --size: ac(108px, 72px);
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--cl-blue);
    background: var(--cl-blue);
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    i {
      font-size: ac(56px, 40px);
      color: var(--cl-black);

      &.icon-play {
        mask-image: url('../../assets/static/mask/play.svg');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        transition: 0.3s;
        background: var(--cl-white);
      }
    }

    @media (max-width: 551px) {
      top: 45%;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -97px;
    background: var(--cl-white);
    mask-image: url('../../assets/static/mask/blog-card-mask.png');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    padding: ac(37px, 32px) ac(88px, 28px) ac(32px, 24px) ac(32px, 20px);

    @media (max-width: 551px) {
      margin-top: -71px;
      mask-image: url('../../assets/static/mask/blog-card-mask-mob.png');
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
    }
  }

  &__list {
    padding-left: ac(32px, 12px);
    width: calc(100% + ac(70px, 48px));
    margin-left: ac(-32px, -20px);
    margin-top: ac(-37px, -32px);
    background: var(--cl-blue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(12px, 10px);
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: ac(37px, 32px);

    li {
      color: var(--cl-black);
      font-size: ac(14px, 13px);
      font-weight: 900;
      line-height: 1.27;
      font-family: var(--font-second);
      text-transform: uppercase;
    }

    &:not(:last-child) {
      margin-bottom: ac(28px, 20px);
    }
  }

  &__text {
    color: var(--cl-black);

    &.heading-25 {
      line-height: 1.32;
      font-size: ac(25px, 18px);

      @media (max-width: 551px) {
        line-height: 1.42;
      }
    }
  }

  &.media-type {
    .blog-card {
      &__list {
        background: var(--cl-pink);

        li {
          color: var(--cl-white);
        }
      }

      &__icon {
        background-color: var(--cl-pink);
        border-color: var(--cl-pink);

        i {
          color: var(--cl-white);
        }
      }
    }
  }

  &:hover {
    .blog-card {
      &__tag {
        background-color: var(--cl-white);
      }

      &__image {
        img {
          transform: scale(1.05);
        }
      }

      &__icon {
        background-color: rgba(255, 255, 255, 0.2);

        i {
          color: var(--cl-blue);

          &.icon-play {
            background-color: var(--cl-blue);
          }
        }
      }
    }

    &.media-type {
      .blog-card {
        &__icon {
          background-color: rgba(255, 255, 255, 0.2);

          i {
            color: var(--cl-blue);

            &.icon-play {
              background-color: var(--cl-blue);
            }
          }
        }
      }
    }
  }
}
