.testimonials-section {
  padding-top: 40px;
  padding-bottom: ac(99px, 40px);
  overflow: visible;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: ac(81px, 32px);
    }
  }

  &__slider {
    max-width: 100%;
    width: 100%;
    position: relative;

    .swiper {
      overflow: visible;
      transition-delay: 0.5s;
      opacity: 1;

      &:not(.swiper-initialized) {
        opacity: 0;
      }

      @media (max-width: 768px) {
        max-width: 304px;
        margin-left: 0;
      }
    }

    @media (min-width: 851px) {
      padding-top: 20px;
      margin-top: -20px;

      .swiper-buttons {
        position: absolute;
        left: 50%;
        top: 20px;
        transform: translateY(-50%) translateX(-50%);
        z-index: 5;

        .swiper-button {
          margin-top: 0;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(30px, 20px);
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.2);
    height: auto;
    position: relative;
    min-height: ac(282px, 260px);

    @media (max-width: 551px) {
      min-height: 391px;
    }
  }

  &__item-text {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.body-text-17-17 {
      font-family: var(--font-main);
      max-height: calc((17px * 1.42) * 10);
    }
  }

  &__item-rating {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(2px, 3px);

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    i {
      font-size: 18px;
      color: var(--cl-orange);
    }

    + .testimonials-section__item-title {
      margin-top: 0;
    }
  }

  &__item-title {
    margin-top: auto;
    max-width: calc(100% - ac(60px, 44px));
  }

  &__item-text {
  }

  &__item-icon {
    position: absolute;
    bottom: ac(-18px, -12px);
    right: ac(-18px, -12px);
    display: flex;
    justify-content: center;
    align-items: center;
    --size: ac(80px, 60px);
    width: var(--size);
    height: var(--size);
    background: var(--cl-dark-grey);
    border-radius: ac(20px, 15px);
    padding: ac(14px, 10px);

    i {
      font-size: ac(53px, 39px);
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.candidate-page {
    padding-top: ac(120px, 40px);
    padding-bottom: ac(120px, 40px);
  }
}
