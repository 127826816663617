.footer {
  overflow: visible;
  padding-bottom: ac(40px, 24px);
  position: relative;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: ac(119px, 48px);
    padding-bottom: ac(151px, 48px);
    position: relative;
    z-index: 1;

    &.not-line {
      &:before {
        content: none;
      }
    }

    &:before {
      top: 0;
      left: 0;
      background: var(--cl-white);
      opacity: 0;
      width: 0;
      height: 1px;
      content: '';
      position: absolute;
    }

    &.animated {
      &:before {
        width: 100%;
        opacity: 1;
      }
    }

    .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(23px, 380px);
        left: ac(-566px, -134px);
        z-index: -2;

        @media (max-width: 640px) {
          bottom: -126px;
          top: auto;

          .aura {
            --size: 288px;
          }
        }
      }

      &:nth-child(2) {
        position: absolute;
        top: ac(-310px, 270px);
        right: ac(-165px, -290px);
        z-index: -1;

        .aura {
          --size: ac(853px, 613px);
        }
      }
    }

    @media (max-width: 1150px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__title {
    max-width: ac(440px, 340px);
    position: relative;
    z-index: 1;
    pointer-events: none;
    margin-bottom: ac(43px, 20px);

    @media (max-width: 768px) {
      margin-bottom: -24px;
    }
  }

  &__contact {
    margin-left: ac(-10px, -20px);
    --size: ac(381px, 327px);
    border: 1px solid var(--cl-white);
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 50%;
    margin-right: ac(142px, 60px);
    position: relative;

    &:before {
      position: absolute;
      inset: ac(-31px, -20px);
      border-radius: 50%;
      content: '';
      background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(17, 246, 238, 0.3) 0%,
          rgba(225, 17, 253, 0) 100%
        ),
        radial-gradient(
          50% 50% at 50% 50%,
          rgba(225, 17, 253, 0.5) 0%,
          rgba(225, 17, 253, 0) 100%
        );
    }

    @media (max-width: 768px) {
      margin-right: 0;
      margin-left: 60px;
    }

    @media (max-width: 551px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 374px) {
      --size: 284px;
    }
  }

  &__contact-link {
    position: relative;
    z-index: 1;
    transition: 0.3s;
    cursor: pointer;

    i {
      --size: ac(36px, 32px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: var(--size);
      height: var(--size);
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      transform: translateY(ac(-7px, -3px));
      transition: 0.3s;
      color: var(--cl-white);

      &.icon-arrow-right {
        font-size: ac(18px, 16px) !important;
      }
    }

    &:hover {
      color: var(--cl-pink);

      i {
        background-color: var(--cl-pink);
        color: var(--cl-white);
      }
    }
  }

  &__contact-social {
    --size: ac(117px, 80px);
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cl-white);
    border-radius: 50%;
    background: transparent;
    transition: 0.3s;
    z-index: 5;
    position: absolute;
    overflow: hidden;

    i {
      font-size: ac(33px, 28px);
    }

    @media (min-width: 552px) {
      &:nth-child(1) {
        right: 0;
        top: -39px;
      }

      &:nth-child(2) {
        right: ac(-92px, -70px);
        top: 39%;
      }

      &:nth-child(3) {
        right: 0;
        bottom: -18px;
      }
    }

    @media (max-width: 551px) {
      &:nth-child(1) {
        left: 0;
        bottom: -27px;
      }

      &:nth-child(3) {
        left: 123px;
        bottom: -47px;

        @media (max-width: 374px) {
          left: 101px;
        }
      }

      &:nth-child(2) {
        right: 0;
        bottom: -27px;
      }
    }

    &:hover {
      @media (max-width: 1024px) {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 20px;

    @media (max-width: 1150px) {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: ac(60px, 96px);
      gap: 20px;
      flex-wrap: wrap;
    }

    @media (max-width: 490px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__link {
    font-size: ac(25px, 20px);
    font-weight: 900;
    line-height: 1.24;
    font-family: var(--font-second);
    text-transform: uppercase;
    transition: color 0.3s ease;
    color: var(--cl-white);
    perspective: 200px;
    transform-style: preserve-3d;

    &.flip-3d {
      .char {
        transition: transform 0.3s cubic-bezier(0.6, 0, 0, 0.6);
        transition-delay: calc(0.3s * (1 - var(--distance-percent)));
        transition-delay: calc(0.3s * var(--distance-percent));
      }
    }

    &:hover {
      color: var(--cl-pink);
      &.flip-3d {
        .char {
          transform: rotateX(-1turn);
          transition: transform 0.45s cubic-bezier(0.6, 0, 0, 0.6);
          transition-delay: calc(0.15s * var(--distance-percent));
          transition-delay: calc(0.15s * (1 - var(--distance-percent)));
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    z-index: 20;

    @media (max-width: 551px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__bottom-text {
    font-size: ac(14px, 13px);
    font-weight: 400;
    line-height: 1.23;
    font-family: var(--font-second);
    text-transform: uppercase;

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }

  &__bottom-dev {
    width: 150px;
    transition: 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 1200px) {
    z-index: 10;
  }
}
