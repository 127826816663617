.first-section {
  padding-top: ac(165px, 131px);
  padding-bottom: ac(37px, 24px);

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 1;

    .aura-container {
      position: absolute;
      top: ac(-529px, -290px);
      right: -22.22%;
      z-index: -1;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: ac(22px, 9px) ac(38px, 14px);
    width: 100%;
    max-width: ac(818px, 318px);
    position: relative;

    &:not(:last-child) {
      margin-bottom: ac(11px, 32px);
    }
  }

  &__title-decor {
    position: absolute;
    top: ac(0px, -2px);
    bottom: ac(0px, -2px);
    width: ac(6px, 4px);

    &:before,
    &:after {
      position: absolute;
      left: 0;
      --size: ac(6px, 4px);
      width: var(--size);
      height: var(--size);
      background: var(--cl-blue);
      content: '';
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &:first-child {
      left: ac(0px, -12px);
    }

    &:last-child {
      right: ac(0px, -12px);
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-transform: uppercase;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &[data-view] {
      .first-section__title-line {
        i {
          transform: translateY(-50%) rotate(30deg);
          transition: 0.5s;
          opacity: 0;
        }
      }

      &.animated {
        .first-section__title-line {
          i {
            transform: translateY(-4px) rotate(0deg);
            opacity: 1;
          }
        }
      }
    }
  }

  &__title-line {
    white-space: nowrap;
    i {
      color: var(--cl-pink);
      font-size: ac(68px, 28px);
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      transform: translateY(ac(-4px, -3px));
      margin-left: ac(27px, 10px);

      @media (max-width: 360px) {
        transform: translateY(-2px);
        font-size: 26px;
      }
    }

    &.gradient-text {
      /*background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      color: var(--cl-black);
      position: relative;
      -webkit-text-stroke: 3px transparent;*/
      --content: var(--content-text, none);
      position: relative;
      z-index: 5;
      color: transparent;

      &:after {
        content: var(--content) !important;
        position: absolute;
        top: 0;
        left: 0;
        font: inherit;
        color: var(--cl-black);
        background: var(--gradient);
        -webkit-background-clip: text;
        -webkit-text-stroke: 4px transparent;
        background-clip: text;
        padding-right: 4px;
      }

      /*color: transparent;

      .word {
        &:first-of-type {
          position: relative;
          background-image: url('../../assets/static/first-title.svg');
          background-size: 100% 100%;
          background-repeat: no-repeat;

          padding-right: ac(16px, 4px);
        }
      }*/

      /*&:before {
        position: absolute;
        top: 0;
        left: 0;
        content: 'boundless';
        z-index: 1;
        font-size: ac(87px, 35px);
        font-weight: 900;
        line-height: 1.2;
        font-family: var(--font-second);
        color: var(--cl-black);
      }*/

      .word {
        /*span {
          color: var(--cl-black);
          !*transform: scale(0.8);*!
        }

        &::after {
          position: absolute;
          font: inherit;
          top: 0;
          left: 0;
          z-index: -1;
          content: var(--content) !important;
          text-shadow: 4px 4px 0px var(--cl-white),
            -4px -4px 0px var(--cl-white);
        }*/
      }
    }
  }

  &.not-found-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ac(120px, 90px);
    padding-bottom: ac(120px, 90px);

    .first-section {
      &__title {
        width: 100%;
        text-align: center;

        h1 {
          display: inline;
        }
      }

      &__text {
        text-align: center;
        padding-top: ac(60px, 32px);

        &:not(:last-child) {
          margin-bottom: ac(40px, 32px);
        }
      }

      &__wrap {
        .aura-container {
          &:nth-child(2) {
            position: absolute;
            top: auto;
            right: auto;
            bottom: ac(-529px, -290px);
            left: -22.22%;
            z-index: -1;
          }
        }
      }
    }
  }

  &.internal-page {
    padding-top: ac(248px, 150px);
    padding-bottom: ac(120px, 40px);

    .first-section {
      &__wrap {
        align-items: flex-start;
      }

      &__title {
        &.heading-90 {
          @media (max-width: 451px) {
            font-size: 32px;
          }
          @media (max-width: 425px) {
            font-size: ac(32px, 25px, 320, 425);
          }

          /* @media (max-width: 360px) {
            font-size: 28px;
          } */
        }
      }
    }

    &.post-detail-page {
      padding-top: ac(156px, 140px);
      /*padding-bottom: 0;*/
      /* overflow: hidden; */

      .first-section {
        &__wrap {
          .aura-container {
            &:nth-child(2) {
              position: absolute;
              top: ac(-51px, 50px);
              left: ac(-100px, -200px);
              z-index: -2;

              .aura {
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(17, 246, 238, 0.35) 0%,
                  rgba(17, 246, 238, 0) 100%
                );
              }
            }

            &:nth-child(3) {
              position: absolute;
              top: ac(673px, 500px);
              left: auto;
              right: ac(-321px, -200px);
              z-index: -2;

              .aura {
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(225, 17, 253, 0.25) 0%,
                  rgba(225, 17, 253, 0) 100%
                );
              }
            }
          }
        }

        &__decor-container {
          width: calc(100% + ac(162px, 100px));
          position: relative;
          z-index: 1;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }

        &__decor {
          position: absolute;
          inset: 0;
          z-index: -1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__content {
          padding-top: ac(97px, 32px);
          padding-left: ac(72px, 30px);
          padding-right: ac(32px, 24px);
          padding-bottom: ac(40px, 32px);
          width: 47.19%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__image {
          mask-image: url('../../assets/static/mask/post-image-mask.svg');
          mask-size: 100% 100%;
          mask-repeat: no-repeat;
          --arw: 745.84;
          --arh: 688.33;
          width: 53.01%;
        }

        &__subtitle {
          &.heading-20 {
            line-height: 1.2;
            color: var(--cl-blue);
            font-size: ac(20px, 16px);

            span {
              font-weight: 400;
              margin-right: ac(10px, 6px);
              color: var(--cl-gray-line);
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(48px, 22px);
          }
        }

        &__title {
          width: 100%;

          &.heading-48 {
            text-transform: none;
            font-weight: 500;
            line-height: 1.2;
            /* @mixin max-line-length 6; */

            @media (max-width: 1430px) {
              font-size: ac(40px, 30px);

              @media (max-width: 360px) {
                font-size: ac(26px, 24px, 320, 375);
              }
            }
          }

          h1 {
            display: unset;
          }

          &.title-animation {
            &.animated {
              .splitting .word {
                animation: slide-in 0.3s cubic-bezier(0.5, 0, 0.5, 1) both;
                animation-delay: calc(50ms * var(--word-index));
                /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        .first-section {
          &__decor-container {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
          }

          &__content {
            width: 100%;
            padding: 0;

            &:not(:last-child) {
              margin-bottom: 32px;
            }
          }

          &__decor {
            @mixin aspect-ratio 768.27, 683.11;
            position: absolute;
            top: auto;
            left: -14px;
            right: auto;
            bottom: 0;
            width: calc(100% + 28px);
            max-width: 415px;
            background-image: url('../../assets/static/post-decor-mob.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            img {
              display: none;
            }
          }

          &__image {
            width: calc(100% + 14px);
            max-width: 400px;

            @media (max-width: 390px) {
              margin-left: -4px;
              width: calc(100% + 18px);
            }
          }
        }
      }
      &.video-page,
      &.podcast-page {
        padding-top: ac(248px, 120px);
      }
      &.video-page,
      &.podcast-page,
      &.job-page {
        /*padding-bottom: ac(99px, 40px);*/
        padding-bottom: ac(99px, 40px);

        .first-section {
          &__video {
            width: 100%;
            border: 1px solid rgba(17, 246, 238, 0.5);
            overflow: hidden;
            border-radius: ac(24px, 16px);
            cursor: pointer;
          }

          &__podcast {
            width: 100%;
          }

          &__content {
            padding: 0;
            width: 100%;
            max-width: ac(1074px, 900px);

            &:not(:last-child) {
              margin-bottom: ac(64px, 28px);
            }
          }
        }

        + .article-section {
          margin-top: ac(-99px, -40px);
        }
      }

      &.job-page {
        .first-section {
          &__wrap {
            padding-top: ac(72px, 40px);
          }

          &__title {
            max-width: ac(924px, 680px);

            &.heading-60 {
              text-transform: none;
              font-weight: 500;
              line-height: 1.2;
            }

            &:not(:last-child) {
              margin-bottom: ac(64px, 24px);
            }
          }

          &__buttons {
            grid-column-gap: ac(40px, 16px);
          }
        }
      }
    }

    &.about-page {
      .first-section {
        &__title-line {
          &.gradient-text {
            margin-top: ac(14px, 0px);

            /*.word {
              &:first-of-type {
                position: relative;
                background-image: url('../../assets/static/first-title-about.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left;
                min-width: ac(806px, 320px);

                padding-right: ac(8px, 4px);

                + .whitespace {
                  + .word {
                    display: none;
                  }
                }
              }
            }*/
          }
        }
      }
    }

    &.candidate-page {
      padding-bottom: 0;
      padding-top: 128px;
      .first-section {
        &__bg {
          position: absolute;
          inset: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:before {
            z-index: 1;
            position: absolute;
            inset: 0;
            background: radial-gradient(
              50% 50% at 50% 50%,
              rgba(8, 7, 18, 0) 0%,
              #080712 100%
            );
            content: '';
          }
        }

        &__wrap {
          padding-top: ac(120px, 40px);
          padding-bottom: ac(120px, 40px);
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-start;
          position: relative;
          z-index: 5;

          @media (max-width: 451px) {
            align-items: flex-start;
          }
        }

        &__title {
          text-align: right;

          &[data-view] {
            .first-section__title-line {
              .icon-title-decor {
                margin-right: 13px;
              }
              i {
                transform: scaleX(-1) translateY(-50%) rotate(30deg);
              }
            }

            &.animated {
              .first-section__title-line {
                i {
                  transform: scaleX(-1) translateY(-4px) rotate(0deg);
                }
              }
            }
          }

          @media (max-width: 451px) {
            text-align: left;

            i {
              display: none;
            }
          }
        }

        &__title-line {
          margin-left: auto;

          @media (max-width: 451px) {
            margin-left: 0;
          }
        }
      }
    }

    &.hiring-page {
      padding-bottom: ac(120px, 30px);
      padding-top: 128px;
      .first-section {
        &__bg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 45%;
          transform: translateX(-50%);
          width: 100%;
          @media (max-width: 1600px) {
            left: 50%;
          }

          img {
            height: 100%;
            object-fit: contain;
            object-position: bottom;
            width: auto;
            margin-left: auto;
            margin-right: ac(99px, 24px);
          }

          &:before {
            z-index: 1;
            position: absolute;
            inset: 0;
            background: linear-gradient(
              180deg,
              rgba(8, 7, 18, 0) 0%,
              #080712 100%
            );
            transform: matrix(-1, 0, 0, 1, 0, 0);
            content: '';
          }
        }

        &__wrap {
          padding-top: ac(120px, 40px);
          padding-bottom: ac(120px, 40px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;
          z-index: 5;

          @media (max-width: 451px) {
            align-items: flex-start;
          }
        }

        &__title {
          .icon-title-decor {
            margin-left: 0px;
            margin-right: ac(12px, 8px);
          }
          &[data-view] {
            .first-section__title-line {
              i {
                transform: scaleX(-1) translateY(-50%) rotate(30deg);
              }
            }

            &.animated {
              .first-section__title-line {
                i {
                  transform: scaleX(-1) translateY(-4px) rotate(0deg);
                  @media (max-width: 520px) {
                    transform: scaleX(-1) translateY(ac(-4px, 2px, 375, 520))
                      rotate(0deg);
                  }
                }
              }
            }
          }
        }
      }
    }

    &.media-page {
      padding-bottom: 0;
      padding-top: 128px;
      .first-section {
        &__bg {
          position: absolute;
          inset: 0;
          bottom: ac(88px, 0px);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:before {
            z-index: 1;
            position: absolute;
            inset: 0;
            background: linear-gradient(
              180deg,
              rgba(8, 7, 18, 0) 0%,
              #080712 100%
            );
            content: '';
          }
        }

        &__wrap {
          padding-top: ac(120px, 40px);
          padding-bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;
          z-index: 5;

          .aura-container {
            &:nth-child(2) {
              position: absolute;
              top: auto;
              right: auto;
              bottom: ac(-194px, -200px);
              left: ac(-99px, -100px);
              z-index: -1;

              .aura {
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(17, 246, 238, 0.5) 0%,
                  rgba(17, 246, 238, 0) 100%
                );
              }
            }
          }
        }

        &__title {
          .first-section__title-line {
            i {
              color: var(--cl-blue);
              margin-left: ac(12px, 6px);
            }
          }
          &[data-view] {
            .first-section__title-line {
              i {
                transform: translateY(-50%) rotate(30deg);
              }
            }

            &.animated {
              .first-section__title-line {
                i {
                  transform: translateY(-4px) rotate(0deg);
                }
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(60px, 32px);
          }
        }

        &__form {
          width: 100%;
          max-width: ac(711px, 600px);
          @media (max-width: 576px) {
            width: 100%;
          }
          .meta-input {
            height: ac(65px, 58px);
            @media (max-width: 576px) {
              width: 100%;
            }
            @media (max-width: 450px) {
              border-bottom: none;
              flex-direction: column;
            }
            input {
              min-width: ac(494px, 280px);
              @media (max-width: 576px) {
                min-width: auto;
              }
              @media (max-width: 450px) {
                border-bottom: 1px solid var(--cl-blue);
                margin-bottom: 14px;
              }
            }
          }
          .btn {
            min-width: fit-content;
            gap: 8px;
            display: flex;
            align-items: center;
            padding: ac(13px, 9px) 21px;
            @media (min-width: 1400px) {
              min-width: 164px;
            }

            i {
              font-size: 19px;
            }

            @media (max-width: 450px) {
              width: 100%;
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(28px, 26px);

            @media (max-width: 451px) {
              margin-bottom: 76px;
            }
          }
        }

        &__filters {
          width: 100%;
        }
      }
    }
  }

  &.first-section-jobs {
    padding-top: ac(248px, 140px);
    padding-bottom: 14px;
    margin-bottom: ac(30px, 17px);
    position: relative;
    .first-section {
      &__image {
        position: absolute;
        inset: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__overlay {
        height: ac(584px, 320px);
        background: linear-gradient(180deg, rgba(8, 7, 18, 0) 0%, #080712 100%);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &__wrap {
        align-items: flex-start;
      }
      &__title-line {
        display: flex;
        gap: ac(40px, 18px);
        align-items: center;
        img {
          max-height: 60px;
          @media (max-width: 520px) {
            display: none;
          }
        }
      }
      &__title h1 {
        display: block;
      }
      &__title-container {
        display: block;
        padding: 0;
        margin-bottom: ac(45px, 32px);
      }

      &__form {
        @media (max-width: 576px) {
          width: 100%;
        }
        .meta-input {
          height: ac(65px, 58px);
          @media (max-width: 576px) {
            width: 100%;
          }
          @media (max-width: 450px) {
            border-bottom: none;
          }
          @media (max-width: 450px) {
            flex-direction: column;
          }
          input {
            min-width: ac(494px, 280px);
            @media (max-width: 576px) {
              min-width: auto;
            }
            @media (max-width: 450px) {
              border-bottom: 1px solid var(--cl-blue);
              margin-bottom: 14px;
            }
          }
        }
        .btn {
          min-width: fit-content;
          gap: 8px;
          display: flex;
          align-items: center;
          padding: ac(13px, 9px) ac(28px, 20px);
          margin-top: ac(-5px, 0);
          i {
            font-size: 19px;
          }

          @media (max-width: 450px) {
            width: 100%;
          }
        }
      }
    }
    .aura-container:not(.aura-container-2) {
      top: -180px;
      left: -40px;
      right: auto;
      .aura {
        --size: ac(863px, 382px);
        opacity: 0.5;
        background: radial-gradient(
          50% 50%,
          rgba(17, 246, 238, 0.916) 0%,
          #11f6ee00 100%
        );
      }
    }
    .aura-container-2 {
      .aura {
        --size: ac(863px, 382px);
        opacity: 0.5;
      }
    }
  }
}
