.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.14);
  border: 1px solid rgba(57, 57, 65, 0.5);
  font-family: var(--font-second);
  font-weight: 800;
  font-size: ac(17px, 15px);
  color: var(--cl-white);
  text-align: center;
  padding: 5px 19px;
  min-height: ac(52px, 48px);
  transition: 0.4s;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  grid-column-gap: 10px;

  &:before {
    inset: 0;
    content: '';
    background: linear-gradient(45deg, #e111fd 0%, #11f6ee 100%);
    position: absolute;
    transition: 0.4s;
    z-index: -1;
    opacity: 0;
    clip-path: circle(0px at 50% 50%);
  }

  i {
    font-size: ac(32px, 30px);
  }

  &:hover {
    background-color: transparent;
    color: var(--cl-black);

    &:before {
      opacity: 1;
      clip-path: circle(75%);
    }
  }

  &.btn-second {
    &:not(:hover) {
      background: rgba(8, 7, 18, 0.4);
    }
  }

  &.btn-gradient {
    border: 1px solid transparent;
    background: transparent;
    color: var(--cl-black);
    z-index: 1;

    &:before {
      background: linear-gradient(45deg, #e111fd 0%, var(--cl-black) 100%);
      opacity: 0.5;
    }

    &:after {
      inset: 0;
      content: '';
      background: linear-gradient(101.59deg, #e111fd 0%, #11f6ee 100%);
      position: absolute;
      transition: 0.3s;
      z-index: -2;
    }

    &:hover {
      border-color: var(--cl-black);
      color: var(--cl-white);
    }
  }

  &.btn-gradient-border {
    position: relative;
    z-index: 1;
    border-color: transparent;
    background: transparent;
    color: var(--cl-white);

    &:after {
      position: absolute;
      inset: 0;
      content: '';
      z-index: -1;
      background: var(--gradient);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      padding: 2px;
      border-radius: inherit;
    }

    &:before {
      background: linear-gradient(101.59deg, #e111fd 0%, #11f6ee 100%);
    }

    &:hover {
      color: var(--cl-black);

      &:before {
        opacity: 1;
      }
    }
  }

  &.btn-white {
    border: 2px solid var(--cl-white);
    background: var(--cl-white);
    color: var(--cl-black);
    z-index: 1;

    &:before {
      background: linear-gradient(-101.59deg, #e111fd 0%, #11f6ee 100%);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      color: var(--cl-grey-2);
    }
  }
}

.buttons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: ac(30px, 16px);
  grid-row-gap: ac(16px, 12px);

  @media (max-width: 551px) {
    width: 100%;

    .btn {
      width: 100%;

      .btn__text {
        flex: 1;
      }
    }
  }
}

.link-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: ac(16px, 10px);
  transition: 0.3s;
  cursor: pointer;
  color: var(--cl-black);
  font-size: ac(17px, 16px);
  font-weight: 900;
  line-height: 1.25;
  font-family: var(--font-second);

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: 36px;
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--cl-grey-2);
    border-radius: 10px;
    background: rgba(8, 7, 18, 0.4);
    transition: 0.3s;
    font-size: 18px;
    color: var(--cl-white);
  }

  &:hover {
    opacity: 0.8;
    i {
      border-color: var(--cl-purple);
      color: var(--cl-blue);
      background-color: rgba(8, 7, 18, 0.2);
    }
  }

  &.link-btn-white {
    color: var(--cl-white);

    i {
      background: rgba(255, 255, 255, 0.2);
    }

    &:hover {
      opacity: 0.8;
      i {
        border-color: var(--cl-purple);
        color: var(--cl-blue);
        background-color: rgba(8, 7, 18, 0.2);
      }
    }
  }

  @media (max-width: 360px) {
    font-size: 15px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 551px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    .btn {
      width: 100%;
    }
  }
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ac(40px, 36px);
  color: var(--cl-white);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--cl-purple);
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ac(8px, 10px);
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: 12px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto 0 0;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    --size: ac(36px, 48px);
    width: var(--size);
    height: var(--size);
    justify-content: center;
    align-items: center;
    color: var(--cl-white, #fff);
    border: 1px solid rgba(57, 57, 65, 0.5);
    border-radius: 10px;
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: ac(40px, 32px);

    &::after {
      display: none;
    }
    &::before {
      content: '\e902';
      display: block;
      font-family: 'icomoon' !important;
      font-size: 19px;
    }

    &.swiper-button-prev {
      &::before {
        content: '\e901';
        /*margin-left: -2px;*/
      }
    }

    &.swiper-button-next {
      &::before {
        /*margin-right: -2px;*/
      }
    }

    &:hover {
      border-color: var(--cl-blue);
      background: var(--cl-purple, #346c50);
    }
    &.swiper-button-lock {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0.7 !important;
    }

    /*&.swiper-button-second {
      border-color: var(--cl-white);
      background: var(--cl-primary);
      color: var(--cl-white);

      &:hover {
        border-color: var(--cl-purple);
        background: var(--cl-light-blue, #346c50);
        color: var(--cl-purple, #fff);
      }
    }*/
  }
}

.arrow-btn {
  --size: ac(59px, 55px);
  border: 1px solid var(--cl-primary);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: border-color 0.2s ease, background-color 0.3s ease,
    padding 0.3s ease;
  transform-origin: left;
  margin-left: -1px;
  background: var(--cl-white);
  cursor: pointer;

  i {
    font-size: 24px;
    position: relative;
    z-index: 5;
    color: var(--cl-primary);
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: var(--cl-primary);

    i {
      color: var(--cl-white);
    }
  }

  &.white-btn {
    background-color: transparent;
    border-color: var(--cl-white);

    i {
      color: var(--cl-white);
    }

    &:hover {
      background-color: var(--cl-white);

      i {
        color: var(--cl-primary);
      }
    }
  }
}

.btn-show-hide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 8px;
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-blue);
  cursor: pointer;
  transition: color 0.3s ease;

  &__icon {
    border: 1px solid var(--cl-blue);
    width: ac(38px, 32px);
    height: ac(38px, 32px);
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;

    i {
      font-size: ac(16px, 14px);
      color: var(--cl-blue);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: opacity 0.3s ease;
    }
  }

  &__show {
    display: flex;
  }

  &__hide {
    display: none;
  }

  &:not(.is-visible) {
    opacity: 0;
    display: none;
  }

  &:not(.active) {
    .btn-show-hide__icon {
      i.icon-plus-second {
        opacity: 1;
      }
    }
  }

  &.active {
    .btn-show-hide__show {
      display: none;
    }

    .btn-show-hide__hide {
      display: flex;
    }

    .btn-show-hide__icon {
      i.icon-plus-second {
        opacity: 0;
      }
    }
  }

  &:hover {
    color: var(--cl-purple);
  }
}
