.values-section {
  padding-top: ac(140px, 40px);
  padding-bottom: ac(99px, 40px);
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }
  &__bg-gradient {
    --size: ac(1007px, 740px);
    width: var(--size);
    height: var(--size);
    position: absolute;
    z-index: -1;
    left: ac(-120px, -280px, 640);
    top: ac(286px, -134px, 375);
    background:  radial-gradient(50% 50% at 50% 50%, rgb(225, 17, 253) 0%, rgba(225, 17, 253, 0) 80%);
    border-radius: 100%;
    opacity: 0.3;
  }
  &__bg {
    --arw: 1108.69;
    --arh: 1203.72;
    width: 89.27%;
    left: 5.31%;
    top: ac(340px, 110px);
    position: absolute;
    z-index: -1;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(51px, 32px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    padding-top: ac(44px, 24px);

    .swiper {
      overflow: visible;
      &:not(.swiper-initialized) {
        .swiper-wrapper {
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-wrap: wrap;
          grid-row-gap: ac(64px, 56px);
          grid-column-gap: 2.42%;

          .values-section__item {
            width: 31.72%;
          }
        }

        + .swiper-buttons {
          display: none;
        }
      }

      @media (max-width: 551px) {
        max-width: 304px;
      }

      + .swiper-buttons {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: ac(56px, 48px);
    padding-left: ac(30px, 20px);
    padding-right: ac(30px, 20px);
    padding-bottom: ac(30px, 24px);
    position: relative;
    height: auto;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border-radius: ac(20px, 16px);
  }

  &__item-icon {
    --arw: 1;
    --arh: 1;
    width: ac(80px, 64px);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-56%);
    z-index: 1;
    background: linear-gradient(101.59deg, #e111fd 0%, #11f6ee 100%);
    border-radius: ac(20px, 16px);

    img {
      object-fit: contain;
    }
  }

  &__item-title {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__item-text {
    text-align: center;
    &[data-scroll] {
      width: calc(100% + 10px);
      margin-left: -10px;
      padding-right: 10px;
    }
  }

  &.offer-type {
    padding-top: ac(99px, 44px);
    .values-section {
      &__wrap {
        .aura-container {
          &:nth-child(1) {
            position: absolute;
            top: ac(-152px, 45px, 1024);
            right: auto;
            left: ac(-219px, -100px);
            z-index: -1;
            @media (max-width: 1023px) {
              top: ac(-152px, -90px);
            }
            @media (min-width: 640px) {
              .aura {
                --size: ac(1037px, 400px);
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(17, 246, 238, 0.5) 0%,
                  rgba(17, 246, 238, 0) 100%
                );
              }
            }
          }

          &:nth-child(2) {
            position: absolute;
            top: ac(-166px, 100px);
            right: ac(-520px, -200px);
            z-index: -1;

            @media (min-width: 640px) {
              .aura {
                --size: ac(1432px, 400px);
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(225, 17, 253, 0.4) 0%,
                  rgba(225, 17, 253, 0) 100%
                );
              }
            }
          }
        }
      }

      &__title {
        &:not(:last-child) {
          margin-bottom: ac(37px, 32px);
        }
      }

      &__item {
        position: relative;
        background-color: rgba(8, 7, 18, 0.9);
        padding: ac(32px, 24px) ac(32px, 20px);
        min-height: ac(263px, 240px);
        border-radius: ac(20px, 16px);
        align-items: flex-start;
      }

      &__item-title {
        padding-left: ac(56px, 40px);
        text-align: left;
        &:not(:last-child) {
          margin-bottom: ac(26px, 22px);
        }
      }

      &__item-text {
        text-align: left;
      }

      &__item-icon {
        top: ac(-18px, -16px);
        left: ac(-18px, -16px);
        transform: none;
      }

      &__slider {
        .swiper {
          &:not(.swiper-initialized) {
            .swiper-wrapper {
              grid-row-gap: ac(40px, 38px);
            }
          }
        }
      }
    }
  }
}
