/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ac(41px, 22px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &.open-menu {
  }

  &.scrolled {
    /* &:not(.open-menu) { */
    background: rgba(var(--cl-primary), 0.5);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    padding: 14px 0;

    &:before {
      opacity: 0.5;
      background-color: var(--cl-primary);
    }
    /* } */

    .logo {
      width: ac(190px, 145px);
    }
  }

  &.open-menu {
    /*.logo {
      svg {
        transition: 0.6s all;
        @media (max-width: 1200px) {
          filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));
        }
        @media (max-width: 640px) {
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
        }
        path {
          fill: var(--cl-aqua);
        }
      }
      &:hover {
        svg {
          path {
            fill: rgba(255, 255, 255, 0.9);
          }
        }
      }
    }*/

    .logo {
      transition: 0.3s;
      @media (max-width: 640px) {
        filter: brightness(300%);
      }
    }
  }

  /*&.header-second {
    &:not(.scrolled) {
      .logo {
        svg {
          path {
            fill: var(--cl-navy);
          }
        }

        &:hover {
          svg {
            path {
              fill: var(--cl-charcoal);
            }
          }
        }
      }

      &:not(.open-menu) {
        @media (min-width: 1201px) {
          .menu .simplebar-content .menu-list {
            border-color: var(--cl-charcoal);
          }
        }

        .btn {
          border-color: var(--cl-navy);

          &.btn-transparent {
            .btn__text {
              color: var(--cl-navy);
            }
          }

          &:not(.btn-transparent) {
            background-color: var(--cl-navy);

            .btn__text {
              color: var(--cl-white);
            }

            .btn__icon {
              color: var(--cl-white);
            }

            &:hover {
              background-color: var(--cl-aqua);

              .btn__text {
                color: var(--cl-navy);
              }

              .btn__icon {
                color: var(--cl-navy);
              }
            }
          }
        }
      }

      @mixin mobile-menu-end-point {
        .menu-link {
          color: var(--cl-charcoal);

          &:before {
            background-color: var(--cl-aqua);
          }

          &:hover {
            color: var(--cl-navy);

            &:before {
              background-color: var(--cl-navy);
            }
          }
        }
      }

      .burger {
        &:not(.active) {
          background-color: var(--cl-white);

          .line {
            stroke: var(--cl-navy);
          }

          &:hover {
            .line {
              stroke: var(--cl-charcoal);
            }
          }
        }
      }
    }
    &.open-menu {
      .logo {
        svg {
          transition: 0.6s all;
          @media (max-width: 1200px) {
            filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));
          }
          @media (max-width: 640px) {
            filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          }
          path {
            fill: var(--cl-white);
          }
        }
        &:hover {
          svg {
            path {
              fill: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    }
  }*/

  .logo {
    --arw: 230;
    --arh: 46;
    height: 100%;
    width: ac(230px, 145px);
    flex-shrink: 0;
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    margin-right: auto;

    img,
    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
      object-position: left;
    }

    /*svg {
      path {
        fill: var(--cl-white);
        transition: fill 0.3s ease;
      }
    }*/

    &:hover {
      transform: scale(0.96);

      /*svg {
        path {
          fill: var(--cl-aqua);
        }
      }*/
    }

    @media (max-width: 360px) {
      width: 135px;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ac(20px, 14px);
      margin-left: ac(128px, 36px);
      position: relative;
      z-index: 10;

      /*.social-link {
        color: var(--cl-light-grey);
        font-size: ac(22px, 24px);

        &:hover {
          color: var(--cl-maron);
        }
      }*/

      .btn {
        &__text {
          padding-left: 20px;
          height: 33px;
          padding-right: 20px;
          font-size: 16px;
          border-radius: 10px;
        }
      }

      @mixin mobile-menu-start-point {
        margin-right: ac(16px, 12px);
      }

      @media (max-width: 640px) {
        display: none;
      }
    }
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }

    @mixin mobile-menu-end-point {
      max-width: ac(710px, 590px);

      &__image {
        display: none;
      }
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /*transition: opacity 0.2s ease;
    opacity: 0;*/

    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      align-items: center;

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(37px, 16px);
          max-height: 33px;

          @media (max-width: 1400px) {
            min-height: 33px;
          }

          @media (max-width: 1279px) {
            gap: 16px;
          }
        }
        &-wrapper {
          @media (min-width: 1200px) and (max-width: 1400px) {
            display: flex;
            justify-content: flex-end;
          }
        }

        &:after {
          display: none;
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .menu-item {
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  .menu-additional-content {
    display: none;

    &__socials {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 20px;

      &:not(:last-child) {
        margin-bottom: ac(32px, 24px);
      }
    }

    &__social {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      transition: 0.3s;
      color: var(--cl-purple);

      &:hover {
        color: var(--cl-purple);
      }
    }

    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: auto;
    }

    @media (max-width: 640px) {
      .menu-additional-content__buttons {
        width: 100%;
        grid-row-gap: 16px;
        margin-bottom: 8px;
        .btn {
          width: 100%;
        }
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @mixin mobile-menu-end-point {
        padding: 5px 0;
      }

      &:hover {
        @mixin mobile-menu-end-point {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    color: var(--cl-black);
    transition: all 0.3s ease;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--cl-black);
      transition: all 0.3s ease;
    }

    &:hover {
      color: var(--cl-purple);

      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-purple);
      }
    }

    &.active {
      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-black);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

.header.header-mobile {
  .burger {
    display: flex;
  }

  .navbar-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 60%;
    height: auto;
    max-height: calc(var(--vh) * 100);
    min-width: 320px;
    padding-top: 96px;
    background: linear-gradient(115.72deg, #e111fd -62.12%, #11f6ee 105.86%);
    padding-left: ac(80px, 40px);
    padding-right: 5px;
    padding-bottom: 66px;
    border-bottom-left-radius: 16px;
    transition: opacity 0.25s ease-out, clip-path 0.5s ease,
      background-color 0.4s ease 0.1s, border-radius 0.3s ease,
      transform 0.5s ease;
    clip-path: circle(0px at calc(100% - ac(110px, 45px)) 45px);
    transform: scaleY(0.3) translateY(-100%);
    transform-origin: top;
    opacity: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    pointer-events: none;
    mask-image: url('../../assets/static/mask/menu-mask.png');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;

    @media (min-width: 650px) {
      width: 50.97% !important;
    }

    @mixin media 970 {
      width: 67%;
    }

    &.active {
      pointer-events: auto;
      clip-path: circle(75%);
      transform: translateY(0);
      opacity: 1;
    }

    &.nav-slide-left {
      right: auto;
      left: 0;
      transform: translateX(-100%);

      &.active {
        transform: translateX(0);
      }
    }

    &.nav-slide-right {
      right: 0;
      left: auto;
      transform: translateX(100%);

      &.active {
        transform: translateX(0);
      }
    }

    &.nav-slide-down {
      right: auto;
      left: 0;
      transform: translateY(-100%);

      &.active {
        transform: translateY(0);
      }
    }

    &.nav-slide-up {
      right: auto;
      left: 0;
      transform: translateY(100%);

      &.active {
        transform: translateY(0);
      }
    }

    .menu {
      width: 100%;
      max-height: 100%;
      min-height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
      align-items: flex-start;
      align-self: center;
      position: relative;
      z-index: 2;
      overflow-x: hidden;
      overflow-y: auto;
      padding-left: 0;
      padding-right: 40px;

      .simplebar-wrapper {
        flex: 1;
      }

      .simplebar-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;

        .menu-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;
        }
      }

      .simplebar-track.simplebar-vertical {
        width: ac(6px, 4px);
        background: var(--cl-grey);
        border-radius: 4px;
        /*transform: translateX(ac(6px, 4px));*/
        .simplebar-scrollbar {
          background: var(--cl-pink);
          border-radius: 4px;
          &:before {
            content: none;
          }
        }
      }

      @media (max-width: 768px) {
        padding-left: 24px;
        padding-right: 19px;
      }
    }

    .menu-list {
      padding-left: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .menu-link {
      font-size: ac(48px, 32px);
      font-weight: 900;
      line-height: 1.2;
      font-family: var(--font-second);
      text-transform: uppercase;
      color: var(--cl-white);

      &:before {
        background-color: var(--cl-white);
      }

      &:hover {
        color: var(--cl-blue) !important;

        &:before {
          background-color: var(--cl-blue) !important;
        }
      }
    }

    .menu-item {
      width: 100%;
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;

      &:last-of-type {
        margin-bottom: 82px;
      }

      @media screen and (max-height: 700px) {
        margin-bottom: 20px;
      }
    }

    @media (max-width: 640px) {
      width: 100%;
      padding-bottom: 24px;
      border-bottom-left-radius: 0;
      padding-top: 184px;
      padding-left: 0;
      mask-image: none;
      height: calc(var(--vh) * 100);

      @media screen and (max-height: 640px) {
        padding-top: 120px;
      }

      .menu {
        padding-left: 24px;
      }

      .menu-item {
        justify-content: flex-start;
      }

      .menu-link {
        font-size: 32px;
        line-height: 1.2;
      }
    }

    .menu-additional-content {
      margin-top: auto;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      position: relative;

      &__socials {
        @media (min-width: 641px) {
          position: absolute;
          right: ac(0px, -16px);
          bottom: ac(0px, -24px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 12px;
        }

        @media (max-width: 640px) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          margin-top: auto;

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }

      &__social {
        --size: ac(64px, 40px);
        width: var(--size);
        height: var(--size);
        font-size: ac(25px, 20px);
        color: var(--cl-black);
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: var(--cl-white);
        border: 1px solid var(--cl-white);
        border-radius: 50%;

        &:hover {
          background-color: var(--cl-blue);
          color: var(--cl-pink);
          border-color: var(--cl-pink);
        }
      }

      &__buttons {
        @media (min-width: 641px) {
          display: none;
        }

        .btn {
          &:not(:hover) {
            border-color: rgba(8, 7, 18, 0.4);
            background-color: rgba(8, 7, 18, 0.5);
          }
        }
      }
    }
  }

  .dropdown-menu {
    position: relative;
    top: 0;
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */

.header__btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: ac(24px, 12px);
  z-index: 100;
  position: relative;
  cursor: pointer;

  &.active {
    .header__btn-icon {
      i {
        transition: 0.1s;
        opacity: 0;
      }
    }

    .burger {
      opacity: 1;
      transition: 0.2s;

      &:before {
        opacity: 1;
      }

      .burger-icon {
        transform: rotate(45deg);
      }

      .top {
        stroke-dashoffset: -68px;
      }

      .bottom {
        stroke-dashoffset: -68px;
      }

      .line {
        stroke: var(--cl-white);
      }
    }
  }

  &:hover {
    .header__btn-text {
      color: var(--cl-blue);
    }

    .header__btn-icon {
      background-color: var(--cl-pink);
    }
  }
}

.header__btn-decor {
  top: ac(-53px, -24px);
  bottom: ac(-62px, -42px);
  left: ac(-35px, -29px);
  position: absolute;
  right: ac(-104px, -60px);
  transition: 0.3s;
  z-index: -1;
  pointer-events: none;

  @media (max-width: 360px) {
    left: -14px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &:nth-child(2) {
    opacity: 0;
  }

  @media (max-width: 1024px) {
    right: -28px;
  }

  @media (min-width: 1441px) {
    right: calc((100vw - 1230px) / 2 * -1);
  }
}

.header__btn-text {
  font-size: ac(17px, 15px);
  font-weight: 900;
  line-height: 1.27;
  font-family: var(--font-second);
  transition: 0.3s;
}

.header__btn-icon {
  --size: ac(29px, 36px);
  width: var(--size);
  height: var(--size);
  border: 1px solid var(--cl-white);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.3s;

  i {
    font-size: ac(17px, 14px);
    color: var(--cl-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 99;
    transition: 0.3s;
  }
}

.header {
  &.open-menu {
    .header__btn-decor {
      &:nth-child(1) {
        opacity: 0;
      }

      &:nth-child(2) {
        @media (min-width: 641px) {
          opacity: 1;
        }
      }
    }
  }
}

.burger {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 100;
  overflow: hidden;
  transition: 0.3s;
  opacity: 0;

  /*&:before {
    z-index: 1;
    inset: 0;
    content: '';
    position: absolute;
    background: var(--gradient);
    transition: opacity 0.3s ease;
    opacity: 0;
  }*/

  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    width: 44px !important;
    height: 44px !important;
    position: relative;
    z-index: 2;
  }

  &.active {
    &:before {
      opacity: 1;
    }

    .burger-icon {
      transform: rotate(45deg);
    }

    .top {
      stroke-dashoffset: -68px;
    }

    .bottom {
      stroke-dashoffset: -68px;
    }

    .line {
      stroke: var(--cl-white);
    }
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
      stroke-dashoffset 400ms;
    stroke: var(--cl-white);
    stroke-width: 3.5;
    stroke-linecap: round;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &:hover {
    .line {
      stroke: var(--cl-blue);
    }
  }

  &.active {
    &:hover {
      .line {
        stroke: var(--cl-blue);
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.35);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
