.heading-90 {
  font-size: ac(90px, 38px);
  font-weight: 900;
  line-height: 1.2;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 32px;
  }
}

.heading-70 {
  font-size: ac(70px, 40px);
  font-weight: 900;
  line-height: 1.24;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 32px;
  }
}

.heading-60 {
  font-size: ac(60px, 32px);
  font-weight: 900;
  line-height: 1.23;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 26px;
  }
}

.heading-48 {
  font-size: ac(48px, 32px);
  font-weight: 900;
  line-height: 1.2;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 26px;
  }
}

.heading-40 {
  font-size: ac(40px, 32px);
  font-weight: 900;
  line-height: 1.23;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 26px;
  }
}

.heading-30 {
  font-size: ac(30px, 22px);
  font-weight: 900;
  line-height: 1.23;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 20px;
  }
}

.heading-25 {
  font-size: ac(25px, 20px);
  font-weight: 900;
  line-height: 1.32;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 18px;
  }
}

.heading-20 {
  font-size: ac(20px, 18px);
  font-weight: 900;
  line-height: 1.32;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 16px;
  }
}

.heading-17 {
  font-size: ac(17px, 16px);
  font-weight: 900;
  line-height: 1.25;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 15px;
  }
}

.heading-16 {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.25;
  font-family: var(--font-second);
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 15px;
  }
}

.heading-15 {
  font-size: ac(15px, 13px);
  font-weight: 900;
  line-height: 1.27;
  font-family: var(--font-second);
  text-transform: uppercase;
}

.body-text-25 {
  font-size: ac(25px, 18px);
  font-weight: 400;
  line-height: 1.56;
}

.body-text-35 {
  font-size: ac(35px, 22px);
  font-weight: 400;
  line-height: 1.43;
  font-family: var(--font-second);

  @media (max-width: 640px) {
    line-height: 1.42;
  }

  @media (max-width: 360px) {
    font-size: 20px;
  }
}

.body-text-25 {
  font-size: ac(25px, 18px);
  font-weight: 400;
  line-height: 1.56;
  font-family: var(--font-second);
}

.body-text-22 {
  font-size: ac(22px, 18px);
  font-weight: 400;
  line-height: 1.48;
}

.body-text-20 {
  font-size: ac(20px, 18px);
  font-weight: 400;
  line-height: 1.48;
}

.body-text-17 {
  font-size: ac(17px, 14px);
  font-weight: 400;
  line-height: 1.58;
}

.body-text-17-17 {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.58;

  @media (max-width: 640px) {
    line-height: 1.42;
  }
}

.content-element {
  &[data-scroll] {
    max-height: calc((ac(18px, 16px) * 1.2) * 10);

    p {
      padding-bottom: 1px;
    }
  }

  &.body-text-17-17 {
    &[data-scroll] {
      max-height: calc((17px * 1.59) * 10);

      @media (max-width: 640px) {
        max-height: calc((17px * 1.43) * 10);
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    a {
      display: inline;
      font: inherit;
      font-weight: 500;
      color: var(--cl-purple);
      transition: color 0.3s ease;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-family: var(--font-md-bt);

      &:hover {
        color: var(--cl-orange);
      }
    }
  }

  h3,
  h4 {
    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }

  li,
  p {
    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    display: grid;
    li {
      position: relative;
      padding-left: ac(40px, 24px);
      &:before {
        position: absolute;
        color: var(--cl-white);
        top: 0;
        left: ac(5px, 0px);
        font: inherit;
        content: counter(num) '.';
        counter-increment: num;
      }

      &:not(:last-child) {
        margin-bottom: ac(18px, 8px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  ul {
    display: grid;

    li {
      position: relative;
      display: block;
      padding-left: ac(40px, 24px);

      &:before {
        position: absolute;
        background: var(--cl-blue);
        border-radius: 0;
        content: '';
        --size: ac(16px, 10px);
        width: var(--size);
        height: var(--size);
        top: 7px;
        left: ac(7px, 4px);
      }

      &:not(:last-child) {
        margin-bottom: ac(18px, 8px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  .simplebar-track.simplebar-vertical {
    width: ac(3px, 2px);
    background: var(--cl-grey);
    border-radius: 0;

    .simplebar-scrollbar {
      background: var(--cl-pink);
      border-radius: 0;

      &:before {
        content: none;
      }
    }
  }

  &.body-text-22 {
    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(16px, 14px);
      }
    }
  }

  &.body-text-20 {
    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(16px, 14px);
      }
    }
  }

  &.body-text-40 {
    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &.article-type {
    font-size: ac(22px, 18px);
    font-weight: 400;
    line-height: 1.5;

    h2,
    h3,
    h4 {
      text-transform: uppercase;
    }

    h1 {
      font-size: ac(44px, 34px);
      font-weight: 900;
      line-height: 1.32;
      font-family: var(--font-second);
      text-transform: uppercase;
      color: var(--cl-blue);

      @media (max-width: 360px) {
        font-size: 26px;
      }

      &:not(:last-child) {
        margin-bottom: ac(32px, 24px);
      }
    }

    h2 {
      font-size: ac(40px, 32px);
      font-weight: 900;
      line-height: 1.32;
      font-family: var(--font-second);
      text-transform: uppercase;
      color: var(--cl-blue);

      @media (max-width: 360px) {
        font-size: 26px;
      }

      &:not(:last-child) {
        margin-bottom: ac(32px, 24px);
      }
    }

    h3 {
      font-size: ac(36px, 20px);
      font-weight: 900;
      line-height: 1.32;
      font-family: var(--font-second);
      text-transform: uppercase;
      color: var(--cl-blue);

      @media (max-width: 360px) {
        font-size: 18px;
      }

      &:not(:last-child) {
        margin-bottom: ac(32px, 24px);
      }
    }

    h4 {
      font-size: ac(20px, 18px);
      font-weight: 900;
      line-height: 1.32;
      font-family: var(--font-second);
      text-transform: uppercase;
      color: var(--cl-blue);

      @media (max-width: 360px) {
        font-size: 16px;
      }

      &:not(:last-child) {
        margin-bottom: ac(24px, 20px);
      }
    }

    p,
    ol,
    ul {
      + h2 {
        padding-top: ac(32px, 16px);
      }

      + h3 {
        padding-top: ac(32px, 16px);
      }

      + h4 {
        padding-top: ac(24px, 16px);
      }
    }

    ul,
    ol {
      + p {
        padding-top: ac(24px, 16px);
      }
    }

    p {
      font-size: ac(22px, 18px);
      font-weight: 400;
      line-height: 1.5;

      + ul {
        padding-top: ac(24px, 16px);
      }

      + ol {
        padding-top: ac(24px, 16px);
      }

      &:not(:last-child) {
        margin-bottom: ac(16px, 14px);
      }
    }

    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(24px, 16px);
      }

      li {
        font-size: ac(20px, 18px);
        font-weight: 400;
        line-height: 1.5;
      }
    }

    img {
      overflow: hidden;
      border-radius: 20px;
      object-fit: contain;
      object-position: left;
      max-height: ac(900px, 700px);
      width: 100%;

      &:not(:first-child) {
        margin-top: ac(40px, 22px);
      }

      &:not(:last-child) {
        margin-bottom: ac(50px, 30px);
      }
    }
  }
}
