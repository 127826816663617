.cyberpunk-image-section {
  overflow: visible;
  position: relative;
  padding-top: ac(15px, 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .aura-container {
    position: absolute;
    top: ac(-289px, -190px);
    left: 6.88%;
    z-index: -1;

    .aura {
      --size: ac(853px, 613px);
    }
  }

  &__image-container {
    width: 100%;
    position: relative;
    min-width: 610px;
  }

  &__image-decor {
    --arw: 1440;
    --arh: 719;
    width: 100%;

    @media (min-width: 1441px) {
      transform: scaleX(1.04);
    }
  }

  &__image-mask {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 4.17%;
    width: 100%;
    --arw: 1440;
    --arh: 659;
    overflow: hidden;
    mask-image: url('../../assets/static/mask/cyberpunk-image-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    top: -32%;
    --arw: 1440;
    --arh: 1200;
  }
}
