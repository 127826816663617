.contact-section {
  padding-top: ac(82px, 30px);
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    > .aura-container {
      position: absolute;
      top: ac(-350px, -372px);
      right: ac(-58px, -222px);
      z-index: -1;

      .aura {
        --size: ac(853px, 626px);
      }
    }
  }
  &__gradient {
    --size: ac(863px, 520px);
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    position: absolute;
    right: ac(-380px, -200px);
    bottom: ac(-92px, -84px);
    opacity: .3;
    background: radial-gradient(50% 50% at 50% 50%, rgb(225, 17, 253) 0%, rgba(225, 17, 253, 0) 100%);
  }
  &__content {
    width: 100%;
    max-width: ac(924px, 600px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__text {
    span {
      position: relative;
      z-index: 1;

      &:before {
        position: absolute;
        inset: ac(-4px, -2px);
        background: var(--cl-pink);
        transform: rotate(-4.32deg);
        content: '';
        z-index: -1;

        @media (max-width: 640px) {
          transform: rotate(-3deg);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    &[data-view] {
      span {
        &:before {
          transform: rotate(0deg);
          opacity: 0;
          transition: 0.4s;
        }
      }

      &.animated {
        span {
          &:before {
            transform: rotate(-4.32deg);
            opacity: 1;

            @media (max-width: 640px) {
              transform: rotate(-3deg);
            }
          }
        }
      }
    }
  }
  &__content-decor {
    width: ac(154px, 80px);
    top: ac(-100px, -80px);
    right: ac(-115px, -90px);
    position: absolute;
    z-index: -1;

    @media (max-width: 640px) {
      top: -112px;
      right: 0;
    }
  }

  &__image-container {
    width: 112.08%;
    position: relative;
    z-index: 1;
    min-width: 643px;
    --arw: 1614;
    --arh: 886.82;
    margin-left: -12.08%;
    margin-top: ac(-47px, -22px);

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }

    > .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(68px, -50px);
        left: ac(0px, -160px);
        z-index: 1;

        .aura {
          --size: ac(853px, 446px);
        }
      }

      &:nth-child(2) {
        position: absolute;
        top: ac(-141px, -150px);
        right: ac(-292px, -285px);
        z-index: 2;

        .aura {
          --size: ac(853px, 542px);
        }
      }
    }

    @media (max-width: 551px) {
      margin-left: -69.08%;
    }
  }

  &__image-decor {
    --arw: 1440;
    --arh: 738;
    width: 89.22%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__image-mask {
    position: absolute;
    z-index: 3;
    right: 5.91%;
    top: 11.97%;
    width: 93.93%;
    --arw: 1516;
    --arh: 781.82;
    overflow: hidden;
    mask-image: url('../../assets/static/mask/global-image-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    bottom: 0;
    --arw: 1517.22;
    --arh: 796.82;

    img {
      object-position: bottom;
    }
  }

  &__image-decor-mask {
    --arw: 1516;
    --arh: 781.82;
    width: 93.93%;
    top: 0;
    right: -3.2%;
    position: absolute;
    z-index: 5;

    img {
      object-fit: contain;
    }
  }

  + .testimonials-section {
    padding-top: ac(0px, 40px);
    margin-top: ac(-38px, -50px);
  }

  &.get-in-touch {
    position: relative;
    padding-top: ac(168px, 72px);
    .contact-section__wrapper {
      position: relative;
      z-index: 20;
      padding-top: 40px;
      padding-bottom: 120px;

      > .aura-container {
        &:nth-child(1) {
          position: absolute;
          top: ac(-524px, -290px);
          right: -22.22%;
          z-index: -1;

          .aura {
            background: radial-gradient(
              50% 50% at 50% 50%,
              rgba(225, 17, 253, 0.5) 0%,
              rgba(17, 246, 238, 0) 100%
            );
          }
        }

        &:nth-child(2) {
          position: absolute;
          top: ac(-371px, 80px);
          left: ac(-361px, -200px);
          z-index: -1;

          .aura {
            background: radial-gradient(
              50% 50% at 50% 50%,
              rgba(17, 246, 238, 0.5) 0%,
              rgba(17, 246, 238, 0) 100%
            );
          }
        }
      }
    }

    .contact-section__image-container {
      position: absolute;
      bottom: -1%;
      right: -16%;

      --arw: 1863;
      --arh: 960;
      width: 117%;

      @media (min-width: 1024px) {
        top: 29%;
      }

      > .aura-container {
        &:nth-child(1) {
          position: absolute;
          top: auto;
          left: auto;
          bottom: ac(-600px, -300px);
          right: ac(-222px, -250px);
          z-index: 2;

          .aura {
            --size: ac(853px, 542px);
            background: radial-gradient(
              50% 50% at 50% 50%,
              rgba(225, 17, 253, 0.25) 0%,
              rgba(225, 17, 253, 0) 100%
            );
          }
        }
      }

      .contact-section__image-decor {
        --arw: 333;
        --arh: 738;
        width: 20%;
        top: 13%;
        right: 11%;
      }

      .contact-section__image-decor-mask {
        --arw: 1516;
        --arh: 781;
        width: 80%;
        top: 13%;
        right: 19.8%;
      }
    }

    .contact-section__form {
      background: var(--cl-black);
      padding: ac(64px, 24px) ac(64px, 24px) ac(56px, 20px);
      border-radius: ac(32px, 28px);
      width: 67.15%;

      .contact-section__form-top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 16px;
        margin-bottom: ac(40px, 20px);

        .meta-input input {
          position: relative;
          z-index: 15;
          @mixin transparent-bg-input var(--cl-white);
        }

        .meta-input:last-child {
          grid-column: 1 / -1;
        }
      }

      .contact-section__form-bottom {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .meta-checkbox__box {
          .check-clip {
            height: 14px;
          }
        }
        .meta-checkbox__text {
          font-family: var(--font-main);
          font-weight: 500;
          font-size: 14px;
          line-height: 1.56;
          display: flex;
          align-items: flex-end;
          @media (max-width: 374px) {
            font-size: ac(14px, 12px, 320, 375);
          }
          a {
            color: var(--cl-blue);
            text-decoration: none;
            transition: 0.3s;
            width: max-content;

            &:hover {
              color: var(--cl-pink);
            }
          }
        }
      }
    }

    .contact-section__form-title {
      margin-bottom: ac(24px, 16px);

      .icon-title-decor {
        font-size: ac(43px, 17px);
        margin-left: ac(20px, 16px);
      }
    }

    .contact-section__form-subtitle {
      color: var(--cl-blue);
      font-family: var(--font-second);
      margin-bottom: ac(48px, 24px);
    }

    @media (max-width: 1024px) {
      .contact-section__form {
        width: 80%;
      }
    }

    @media (max-width: 768px) {
      .contact-section__form-bottom {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 10px;
      }
    }

    @media (max-width: 540px) {
      .contact-section__form {
        width: 100%;
      }

      .contact-section__form-top {
        display: flex !important;
        flex-direction: column;
      }

      .contact-section__form-title {
        &.heading-60 {
          font-size: 20px;
        }

        .first-section__title-line {
          display: flex;
          align-items: flex-end;
        }
      }

      .contact-section__form-bottom {
        .meta-checkbox__text {
          display: block !important;
        }

        .btn {
          margin: 0 auto;
        }
      }
    }
  }
}
