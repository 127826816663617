.business-section {
  overflow: hidden;

  &__wrapper {
    position: relative;
    z-index: 1;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
      gap: ac(50px, 20px);
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__map-wrapper {
    position: relative;
    z-index: 2;
    min-width: 100%;
    min-height: ac(511px, 300px);
    max-height: ac(511px, 300px);
    background: rgba(8, 7, 18, 0.4);
  }

  .business-section__map {
    z-index: 2;
    width: 100%;
    min-height: ac(511px, 300px);
    max-height: ac(511px, 300px);
    mix-blend-mode: luminosity;
    opacity: 0.8;
  }

  &__block {
    position: absolute;
    top: 24px;
    right: 24px;
    background: var(--cl-white);
    padding: ac(32px, 20px) ac(40px, 20px) ac(35px, 20px) ac(40px, 20px);
    width: max-content;
    z-index: 15;
    border-radius: ac(24px, 20px);
    overflow: hidden;
    margin-left: auto;
    min-width: 480px;

    @media (max-width: 1024px) {
      position: relative;
      inset: auto;
      margin-right: 24px;
    }

    @media (max-width: 540px) {
      width: 90%;
      margin: 0 auto;
      min-width: auto;
    }
  }

  &__title {
    color: var(--cl-black);
    margin-bottom: ac(20px, 16px);
  }

  &__list {
    color: var(--cl-black);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--cl-blue);
    padding: ac(11px, 8px) 0;

    span {
      text-transform: uppercase;
      line-height: 132%;
      font-family: var(--font-second);

      &:first-of-type {
        font-weight: 900;
        color: var(--cl-pink);
      }

      &:last-of-type {
        font-weight: 400;
        width: 59%;

        @media (max-width: 540px) {
          width: 75%;
        }

        @media (max-width: 360px) {
          width: 69%;
        }
      }

      @media (max-width: 360px) {
        font-size: 14px;
      }
    }
  }
}
