.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: var(--cl-navy);

  &__icon {
    width: ac(16px, 14px);
    height: ac(16px, 14px);

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: 16px;
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      &.icon-close {
        opacity: 0;
        color: var(--cl-navy);
      }
    }
  }

  &.active {
    .filters-btn__icon {
      i.icon-close {
        opacity: 1;
      }

      i.icon-filters {
        opacity: 0;
      }
    }
  }

  &:not(.active) {
    &:hover {
      .filters-btn__icon {
        i {
          color: var(--white);
        }
      }
    }
  }

  &:hover {
    .filters-btn__icon {
      transform: none;
    }
  }
}

.jobs-search {
  padding-top: ac(77px, 24px);
  padding-bottom: ac(100px, 38px);
  overflow: visible;
  position: relative;
  z-index: 20;
  &__gradient {
    position: absolute;
    --size: ac(863px, 420px);
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(17, 246, 238, 0.903) 0%,
      rgba(17, 246, 238, 0) 100%
    );
    right: 42px;
    top: ac(448px, 500px);
    opacity: 0.25;
    z-index: -1;
  }
  &__subtitle {
    width: 100%;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    @media (max-width: 640px) {
      br {
        display: none;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    flex-direction: row;
  }

  &__sidebar {
    flex: 1;
    max-width: 21%;
    @media (max-width: 992px) {
      max-width: 28%;
    }
  }
  &__sidebar-inner {
    padding-top: ac(100px, 84px) !important;
    .btn-close__icon {
      transition: 0.3s all;
    }
    .btn-close__icon:hover {
      opacity: 0.85;
    }
  }
  &__sidebar-filters-title {
    width: 100%;
    margin-bottom: ac(42px, 24px);
    position: relative;
    font-family: var(--font-second);
    font-weight: 900;
    font-size: ac(25px, 22px);
    line-height: 1.32;
    text-transform: uppercase;
    color: #fff;
    span {
      @media (max-width: 640px) {
        display: block;
        max-width: 120px;
      }
    }
    .btn-close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 5;
      @media (min-width: 641px) {
        display: none !important;
      }
    }
  }

  &__sidebar-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(32px, 24px);

    .btn-show-hide {
      margin-top: ac(12px, 10px);
    }
  }

  &__sidebar-item-content {
    /*padding-top: 6px;*/
    transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    /*grid-column-gap: 10px;
    grid-row-gap: 9px;*/
    overflow: hidden;

    &.show-hide-content {
    }

    &.with-inputs {
      grid-row-gap: ac(12px, 10px);
      overflow: visible;

      .meta-select,
      .meta-input {
        width: 100%;
      }
    }
  }

  &__sidebar-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: 8px;
    transition: min-height 0.4s ease;
  }

  &__sidebar-accordion-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: all 0.4s ease;

    &.active {
      .jobs-search__sidebar-accordion-item-top {
        margin-bottom: ac(24px, 18px);
      }

      .jobs-search__sidebar-accordion-item-btn {
        transform: scaleY(1);
      }

      .jobs-search__sidebar-accordion-item-content {
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;

      .jobs-search__sidebar-accordion-item-content {
        max-height: 0 !important;
      }

      .jobs-search__sidebar-accordion-item-btn {
        transform: scaleY(-1);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(23px, 18px);
    }
  }

  &__sidebar-accordion-item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
    margin-bottom: 0;
    position: relative;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--cl-blue);
    cursor: pointer;
    &:hover {
      opacity: 0.71;
      @media (min-width: 768px) {
        .jobs-search__sidebar-accordion-item-title {
          transform: translateX(4px);
        }
      }
    }
  }

  &__sidebar-accordion-item-title {
    max-width: calc(100% - 40px);
    font-weight: 900;
    font-size: 16px;
    line-height: 1.32;
    text-transform: uppercase;
    color: var(--cl-blue);
    font-family: var(--font-second);
    transition: 0.27s all ease-in-out;
  }

  &__sidebar-accordion-item-btn {
    --size: ac(32px, 28px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;
    i {
      font-size: 11px;
      transition: all 0.4s ease;
      color: var(--cl-white);
      pointer-events: none;
      position: absolute;
      left: 46%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }

    &:hover {
      i {
        color: var(--cl-navy);
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  &__sidebar-accordion-item-content {
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    margin-left: 2px;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    width: 100%;

    .meta-checkbox {
      &:hover {
        /* .meta-checkbox__box {
          border-color: var(--cl-navy);
        }
        .meta-checkbox__text {
          color: var(--cl-navy);
        } */
      }
      &:not(:last-child) {
        margin-bottom: ac(18px, 12px);
      }

      &.is-hidden {
        display: none;
      }
    }
  }

  &__sidebar-accordion-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(1px, 3px);
    width: 100%;

    /* .meta-checkbox__text {
      transform: translateY(2px);
      color: var(--cl-charcoal);
    } */
    .meta-checkbox__box {
      /* i.icon-check {
        color: var(--cl-charcoal) !important;
      } */
    }

    .meta-select {
      width: 100%;

      .choices {
        &__inner {
          background: var(--cl-white);
          border-color: rgba(53, 53, 63, 0.3);

          .choices__placeholder {
            color: var(--cl-charcoal) !important;
          }
        }

        &__list {
          background-color: var(--cl-white);
        }

        &:after {
          border-color: var(--cl-charcoal);
        }
      }

      &:not(:first-child) {
        margin-top: ac(9px, 11px);
      }
    }
  }

  &__sidebar-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: ac(16px, 14px);

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(.btn-navy) {
        border-color: var(--cl-navy);

        .btn__text {
          color: var(--cl-navy);
        }

        &:hover {
          border-color: var(--cl-navy);
        }
      }
    }
  }

  &__result {
    width: 74.4%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    @media (max-width: 992px) {
      width: 67%;
    }

    @media (max-width: 640px) {
      position: relative;
    }
  }

  &__title {
    font-weight: 900;
    font-size: ac(48px, 32px);
    font-family: var(--font-second);
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    &:not(:last-child) {
      margin-bottom: ac(60px, 28px);
    }
  }

  &__subtitle {
    margin-top: ac(-4px, 0px);
    font-size: ac(24px, 20px);
    color: var(--cl-navy);
    line-height: 1.32;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__result-bg {
    display: none;
    @media (max-width: 640px) {
      display: block;
      position: absolute;
      inset: 0;
      z-index: 10;
      pointer-events: none;
    }
  }

  &__result-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ac(30px, 18px);
    grid-row-gap: ac(30px, 18px);

    @media (max-width: 901px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__filters-btn {
    @media (min-width: 641px) {
      display: none !important;
    }
  }

  @media (max-width: 1280px) {
    &__sidebar {
      /* max-width: 30%; */
    }
  }

  @media (max-width: 768px) {
    &__sidebar {
      max-width: 40%;
    }

    &__result {
      width: 55%;
    }
  }

  @media (max-width: 640px) {
    overflow: hidden;

    &__container {
      flex-direction: row;
    }

    &__sidebar-inner {
      padding-top: 20px !important;
    }

    &__sidebar {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      opacity: 0;
      max-width: 280px;
      z-index: 2;
      transition: all 0.4s ease;

      @mixin media 374 {
        max-width: 100%;
      }
    }

    &__result {
      width: 100%;
      transition: all 0.3s ease;
      transform: translateX(0);
      opacity: 1;
      position: relative;
    }

    &__filters-btn {
      display: flex;
      margin-right: auto;
      margin-bottom: 24px;
    }

    &__container {
      &.active {
        .jobs-search__sidebar {
          position: relative;
          opacity: 1;
          transform: translateX(0);
        }

        .jobs-search__result {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(305px);
          opacity: 0.4;

          & > * {
            pointer-events: none;
          }

          @mixin media 374 {
            transform: translateX(110%);
          }
        }

        .jobs-search__result-bg {
          pointer-events: auto;
        }
      }
    }
  }
}

.jobs-card {
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.4);
  border-radius: ac(20px, 18px);
  padding: ac(20px, 16px);
  min-height: ac(298px, 262px);
  &:hover {
    .jobs-card {
      &__inner {
        transform: scale(1.01);
      }
    }
  }
  &__inner {
    border: 1px solid #fff;
    border-radius: ac(15px, 12px);
    padding: ac(20px, 16px);
    display: flex;
    transition: 0.2s all;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__title {
    font-weight: 900;
    font-size: ac(25px, 20px);
    text-transform: uppercase;
    color: #fff;
    font-family: var(--font-second);
    margin-bottom: 7px;
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  &__tag {
    padding: 5px 7px;
    font-weight: 900;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 15px;
    background: var(--cl-pink);
    line-height: 1.3;
    font-family: var(--font-second);
  }
  &__arrow {
    &-wrap {
      display: flex;
      justify-content: flex-end;
    }
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(57, 57, 65, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      i {
        transform: translateX(2px);
      }
    }
    i {
      color: #fff;
      font-size: 20px;
      transition: 0.3s all;
    }
  }
}
