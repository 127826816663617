.alert-section {
  padding-top: ac(92px, 32px);
  padding-bottom: ac(60px, 32px);
  color: var(--cl-black);
  position: relative;

  .cont {
    position: relative;
    z-index: 10;
  }

  &__border {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 1px;
    transform: translateY(ac(60px, 32px)) translateZ(0) !important;
    z-index: -1;

    &:before {
      background: var(--cl-white);
      bottom: 0;
      height: 1px;
      position: absolute;
      content: '';
      opacity: 0;
      width: 0;
      transition: 0.5s;
      max-width: calc(100% - ac(198px, 40px));
    }

    &.animated {
      &:before {
        width: 100%;
        opacity: 1;
      }
    }
  }

  &__wrap {
    position: relative;
    padding-left: ac(61px, 20px);
    padding-top: ac(72px, 32px);
    padding-bottom: ac(72px, 32px);
    min-height: ac(521px, 400px);
    clip-path: inset(1px ac(-19px, -10px) -1000px ac(-19px, -10px));
    transform: translateZ(0);
  }

  &__decor {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(32px, 28px);
    position: absolute;
    z-index: 6;
    top: ac(24px, 20px);
    right: 33.09%;
    transform: scaleX(-1);
  }

  &__desk-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: ac(-19px, -10px);
    width: 71.5%;
    z-index: 5;
    border-radius: ac(32px, 20px) 0 0 ac(32px, 20px);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;

      @media (min-width: 1430px) {
        width: 1030px;
        min-width: 1030px;
        margin-left: -142px;
      }
    }

    @media (max-width: 1100px) {
      width: 75%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49.52%;
    position: relative;
    z-index: 10;

    @media (min-width: 1300px) {
      min-width: ac(615px, 500px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(48px, 32px);
    }
  }

  &__image-container {
    position: absolute;
    top: 0;
    right: ac(-19px, -10px);
    bottom: 0;
    width: ac(612px, 300px);
    overflow: hidden;
    border-radius: 0 ac(32px, 20px) ac(32px, 20px) 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image-decor {
    --arw: 569;
    --arh: 1094;
    width: 92.52%;
    left: -22.11%;
    top: -14.94%;
    position: absolute;

    @media (max-width: 1429px) {
      top: -10px;
    }
  }

  &__form {
    width: 100%;
    max-width: ac(533px, 400px);

    .meta-select {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }

    .meta-input {
      &.second-type {
        position: relative;
        border-bottom: 1px solid var(--cl-black);

        svg {
          --size: ac(32px, 20px);
          position: absolute;
          left: ac(16px, 0px);
          top: 10px;
          width: var(--size);
          height: var(--size);
        }

        input {
          color: var(--cl-black);
          padding-left: ac(56px, 24px);
          @mixin transparent-bg-input var(--cl-black);

          &::placeholder {
            color: var(--cl-black);
          }
        }

        @media (max-width: 450px) {
          border-bottom: none;
        }
      }

      @media (max-width: 576px) {
        width: 100%;
      }
      @media (max-width: 450px) {
        border-bottom: none;
      }
      @media (max-width: 450px) {
        flex-direction: column;
      }

      input {
        flex: 1;
        @media (max-width: 576px) {
          min-width: auto;
        }
        @media (max-width: 450px) {
          border-bottom: 1px solid var(--cl-black);
          margin-bottom: 14px;
        }
      }
    }
    .btn {
      min-width: fit-content;
      gap: 8px;
      display: flex;
      align-items: center;
      @media (max-width: 450px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .alert-section {
      &__decor {
        right: 10px;
        top: 10px;
      }

      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 16px;
        overflow: hidden;
        background: var(--gradient);
        padding-right: 20px;
        padding-top: 40px;

        @media (max-width: 451px) {
          padding-bottom: 0;
        }
      }

      &__desk-bg {
        display: none;
      }

      &__content {
        width: 100%;

        padding-bottom: 0;

        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }
      }

      &__image-container {
        --arw: 755.98;
        --arh: 602;
        width: 100%;
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        mask-image: url('../../assets/static/mask/alert-image-mob-mask.svg');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        overflow: hidden;
        border-radius: 0;

        &::before {
          content: '';
          display: block;
          padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
        }

        @media (max-width: 451px) {
          margin-left: -20px;
          width: calc(100% + 40px);
        }
      }

      &__image-decor {
        top: -80px;
      }

      &__image {
        inset: unset;
        @mixin aspect-ratio 755.98, 602;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
