.jobs-section {
  padding-top: ac(59px, 25px);
  padding-bottom: ac(70px, 50px);
  overflow: visible;
  --sw-margin: ac(60px, 20px);

  &__decor {
    --arw: 1440;
    --arh: 501;
    position: absolute;
    bottom: ac(-83px, -53px);
    min-width: 1033px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
  }

  &__title {
    @media (min-width: 641px) {
      br {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__buttons {
    &:not(:last-child) {
      margin-bottom: ac(40px, 32px);
    }

    @media (min-width: 1023px) {
      margin-top: ac(60px, 32px);
      width: 100%;
      justify-content: center;
      order: 3;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    position: relative;

    .swiper {
      overflow: visible;
      transition-delay: 0.5s;
      opacity: 1;

      &:not(.swiper-initialized) {
        opacity: 0;
      }

      @media (max-width: 551px) {
        max-width: 288px;
        margin-left: 0;
      }
    }

    .swiper-buttons {
      @media (min-width: 1023px) {
        position: absolute;
        top: calc(var(--sw-margin) * -1);
        transform: translateY(-100%);
        right: 0;
        margin: 0;

        .swiper-button {
          margin-top: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(60px, 32px);
    }
  }

  &__bottom-decor {
    order: 10;
    --arw: 1;
    --arh: 1;
    width: ac(154px, 120px);
    margin-top: ac(-45px, 20px);
    margin-left: auto;
    margin-right: ac(203px, 0px);

    @media (max-width: 640px) {
      margin-right: auto;
    }
  }

  &.candidate-page {
    padding-top: ac(97px, 40px);
    padding-bottom: ac(32px, 40px);

    .aura-container {
      position: absolute;
      bottom: ac(-449px, -200px);
      right: ac(-59px, -200px);
      z-index: -1;

      .aura {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(225, 17, 253, 0.5) 0%,
          rgba(225, 17, 253, 0) 100%
        );
      }
    }

    .jobs-section {
      &__decor {
        bottom: ac(83px, 60px);
      }

      &__buttons {
        @media (min-width: 1023px) {
          margin-top: 0;
        }
      }
    }
  }

  &.job-page {
    padding-bottom: ac(99px, 40px);

    .jobs-section {
      &__wrap {
        position: relative;
        z-index: 1;

        .aura-container {
          &:nth-child(1) {
            position: absolute;
            top: ac(-474px, -200px);
            left: ac(-132px, -200px);
            z-index: -1;

            .aura {
              background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(17, 246, 238, 0.5) 0%,
                rgba(17, 246, 238, 0) 100%
              );
            }
          }

          &:nth-child(2) {
            position: absolute;
            bottom: ac(-26px, -40px);
            right: ac(52px, -200px);
            z-index: -1;

            .aura {
              background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(225, 17, 253, 0.3) 0%,
                rgba(225, 17, 253, 0) 100%
              );
            }
          }
        }
      }
    }
  }
}
