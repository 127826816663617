.talk-section {
  padding-top: ac(99px, 40px);
  padding-bottom: ac(99px, 40px);
  position: relative;
  z-index: 2;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.33%;
  }

  &__title-decor {
    --arw: 1;
    --arh: 1;
    width: 100%;
    max-width: ac(660px, 320px);

    img {
      object-fit: contain;
      object-position: left;
    }

    &:not(:last-child) {
      margin-bottom: ac(8px, 12px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(64px, 24px);
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 8px;
  }

  &__contact-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.3s;
    grid-column-gap: ac(12px, 8px);
    color: var(--cl-blue);

    i {
      font-size: ac(40px, 32px);
    }

    &:hover {
      color: var(--cl-purple);
    }

    &.heading-20 {
      @media (max-width: 451px) {
        font-size: 15px;
        grid-column-gap: 6px;
        letter-spacing: -0.02em;

        i {
          font-size: 20px;
        }
      }

      @media (max-width: 374px) {
        font-size: 12px;
      }
    }
  }

  &__images {
    width: 36.71%;
    position: relative;
    margin-right: ac(43px, 0px);

    &[data-view] {
      .talk-section {
        &__image-container {
          transition: 0.8s;

          &:nth-child(1) {
            transform: scale(0.7) translateX(25%);
            opacity: 0;
          }

          &:nth-child(2) {
            transform: scale(0.7) translateX(-75%);
            opacity: 0;
          }
        }
      }

      &.animated {
        .talk-section {
          &__image-container {
            &:nth-child(1) {
              transform: scale(1) translateX(0%);
              opacity: 1;
            }

            &:nth-child(2) {
              transform: scale(1) translateX(0%);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__image-container {
    width: 100%;
    padding: ac(32px, 24px);
    border: 1px solid transparent;
    border-radius: 50%;

    background: var(--cl-black);

    &:nth-child(2) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 79.61%;
      z-index: 1;
    }
  }

  &__image {
    width: 100%;
    border-radius: 50%;
  }

  @media (max-width: 640px) {
    .talk-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 30px;
      }

      &__content {
        width: 100%;
      }

      &__images {
        width: 100%;
        max-width: 330px;
      }
    }
  }


  &.get-in-touch {
    .talk-section__wrap {
      @media (min-width: 641px) {
        flex-direction: row-reverse;
      }
    }

    .talk-section__images {
      margin-right: 0;
      margin-left: -28%;

      @media (max-width: 640px) {
        margin: 0;
      }
    }
  }
}
