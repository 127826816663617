.filters-slider {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-left: 8px;

  .swiper {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    overflow: visible;
  }

  &__helper {
    top: -20px;
    right: -10px;

    @media (min-width: 769px) {
      display: none;
    }
  }

  &__item {
    width: min-content;
    input {
      display: none;

      &:checked {
        + .filters-slider__item-toggle {
          color: var(--cl-white);

          &:before {
            background-color: var(--cl-pink);
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__item-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: ac(20px, 16px);
    padding-right: ac(20px, 16px);
    min-height: 48px;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    text-transform: none;

    transition: 0.3s;
    color: var(--white);
    cursor: pointer;

    &:before {
      position: absolute;
      border: 1px solid var(--cl-pink);
      inset: 0;
      content: '';
      z-index: -1;
      transform: skewX(-25deg);
      pointer-events: none;
      transition: 0.3s;
      background: transparent;
    }

    &:hover {
      color: var(--cl-blue);

      &:before {
        border-color: var(--cl-blue);
      }
    }
  }
}

.swiper-mobile-helper {
  position: absolute;
  width: 45px;
  height: 45px;
  opacity: 0;
  pointer-events: none;
  will-change: transform, opacity;
  animation: none;
  z-index: 20;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.show {
    animation: swiperHelper 1.3s ease 2 both;
  }
}

@keyframes swiperHelper {
  0% {
    transform: translateX(0);
    opacity: 0.6;
  }
  39% {
    opacity: 0.6;
  }
  56% {
    opacity: 0.15;
  }
  77% {
    transform: translateX(-150%);
    opacity: 0;
  }
}
