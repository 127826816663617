.global-section {
  padding-top: ac(82px, 30px);
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    > .aura-container {
      position: absolute;
      top: ac(-350px, -372px);
      right: ac(-58px, -222px);
      z-index: -1;

      .aura {
        --size: ac(853px, 626px);
      }
    }
  }

  &__content {
    width: 100%;
    max-width: ac(924px, 600px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__text {
    span {
      position: relative;
      z-index: 1;

      &:before {
        position: absolute;
        inset: ac(-4px, -2px);
        background: var(--cl-pink);
        transform: rotate(-4.32deg);
        content: '';
        z-index: -1;

        @media (max-width: 640px) {
          transform: rotate(-3deg);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    &[data-view] {
      span {
        &:before {
          transform: rotate(0deg);
          opacity: 0;
          transition: 0.4s;
        }
      }

      &.animated {
        span {
          &:before {
            transform: rotate(-4.32deg);
            opacity: 1;

            @media (max-width: 640px) {
              transform: rotate(-3deg);
            }
          }
        }
      }
    }
  }
  &__content-decor {
    width: ac(154px, 80px);
    top: ac(-100px, -80px);
    right: ac(-115px, -90px);
    position: absolute;
    z-index: -1;

    @media (max-width: 640px) {
      top: -112px;
      right: 0;
    }
  }

  &__image-container {
    width: 112.08%;
    position: relative;
    z-index: 1;
    min-width: 643px;
    --arw: 1614;
    --arh: 886.82;
    margin-left: -12.08%;
    margin-top: ac(-47px, -22px);

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }

    > .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(68px, -50px);
        left: ac(0px, -160px);
        z-index: 1;

        .aura {
          --size: ac(853px, 446px);
        }
      }

      &:nth-child(2) {
        position: absolute;
        top: ac(-141px, -150px);
        right: ac(-292px, -285px);
        z-index: 2;

        .aura {
          --size: ac(853px, 542px);
        }
      }
    }

    @media (max-width: 551px) {
      margin-left: -69.08%;
    }
  }

  &__image-decor {
    --arw: 1440;
    --arh: 738;
    width: 89.22%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__image-mask {
    position: absolute;
    z-index: 3;
    right: 5.91%;
    top: 11.97%;
    width: 93.93%;
    --arw: 1516;
    --arh: 781.82;
    overflow: hidden;
    mask-image: url('../../assets/static/mask/global-image-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    bottom: 0;
    --arw: 1517.22;
    --arh: 796.82;

    img {
      object-position: bottom;
    }
  }

  &__image-decor-mask {
    --arw: 1516;
    --arh: 781.82;
    width: 93.93%;
    top: 0;
    right: -3.2%;
    position: absolute;
    z-index: 5;

    img {
      object-fit: contain;
    }
  }

  + .testimonials-section {
    padding-top: ac(0px, 40px);
    margin-top: ac(-38px, -50px);
  }
}
