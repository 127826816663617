.career-section {
  padding-top: ac(70px, 40px);
  padding-bottom: ac(59px, 40px);
  overflow: visible;
  --sw-margin: ac(60px, 32px);
  position: relative;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(-26px, -30px);
        left: ac(-177px, -200px);
        z-index: -1;

        @media (min-width: 640px) {
          .aura {
            background: radial-gradient(
              50% 50% at 50% 50%,
              rgba(17, 246, 238, 0.3) 0%,
              rgba(17, 246, 238, 0) 100%
            );
          }
        }
      }

      &:nth-child(2) {
        position: absolute;
        top: ac(-400px, -40px);
        right: ac(-47px, -200px);
        z-index: -1;

        @media (min-width: 640px) {
          .aura {
            background: radial-gradient(
              50% 50% at 50% 50%,
              rgba(225, 17, 253, 0.3) 0%,
              rgba(225, 17, 253, 0) 100%
            );
          }
        }
      }
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__buttons {
    margin-top: -10px;
    @media (min-width: 1023px) {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    position: relative;

    .swiper {
      overflow: visible;
      transition-delay: 0.5s;
      opacity: 1;

      &:not(.swiper-initialized) {
        opacity: 0;
      }

      @media (max-width: 551px) {
        max-width: 304px;
        margin-left: 0;
      }
    }

    .swiper-buttons {
      align-items: flex-end;
      @media (min-width: 1023px) {
        position: absolute;
        top: calc(var(--sw-margin) * -1);
        transform: translateY(-100%);
        right: 0;
        margin: 0;

        .swiper-button {
          margin-top: 0;
        }
      }

      @media (max-width: 1022px) {
        .btn {
          display: none;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.2);
    border-radius: ac(20px, 15px);
    padding: ac(30px, 20px) ac(30px, 26px) ac(55px, 44px) ac(30px, 20px);
    position: relative;
    height: auto;

    @media (max-width: 374px) {
      padding-right: 20px;
    }
  }

  &__item-title {
    text-transform: uppercase;
    max-width: 90%;
    &:not(:last-child) {
      margin-bottom: ac(26px, 12px);
    }
  }

  &__item-text {
  }

  &__item-icon {
    position: absolute;
    bottom: ac(-18px, -12px);
    right: ac(-18px, -12px);
    display: flex;
    justify-content: center;
    align-items: center;
    --size: ac(80px, 60px);
    width: var(--size);
    height: var(--size);
    background: var(--cl-dark-grey);
    border-radius: ac(20px, 15px);
    padding: ac(14px, 10px);

    i {
      font-size: ac(47px, 36px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
