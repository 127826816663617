.blogs-section {
  overflow: visible;
  padding-top: ac(96px, 33px);
  padding-bottom: ac(58px, 72px);
  --sw-margin: ac(58px, 20px);
  position: relative;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 5;

    .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(224px, 380px);
        left: ac(-566px, -269px);
        z-index: -2;

        .aura {
          --size: ac(853px, 673px);
        }
      }

      &:nth-child(2) {
        position: absolute;
        top: ac(-88px, -297px);
        right: ac(-164px, -268px);
        z-index: -1;

        .aura {
          --size: ac(853px, 863px);
        }
      }
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__buttons {
    &:not(:last-child) {
      margin-bottom: ac(40px, 32px);
    }

    @media (min-width: 1023px) {
      margin-top: ac(60px, 32px);
      width: 100%;
      justify-content: center;
      order: 3;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    position: relative;

    .swiper {
      overflow: visible;

      &:not(.swiper-initialized) {
        display: none;
      }

      @media (max-width: 690px) {
        max-width: 420px;
        margin-left: 0;
      }
    }

    .swiper-buttons {
      @media (min-width: 1023px) {
        position: absolute;
        top: calc(var(--sw-margin) * -1);
        transform: translateY(-100%);
        right: 0;
        margin: 0;

        .swiper-button {
          margin-top: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(60px, 32px);
    }
  }

  &.insights-page {
    .blogs-section {
      &__wrap {
        .aura-container {
          &:nth-child(2) {
            position: absolute;
            top: ac(-156px, -80px);
            left: ac(-132px, -200px);
            z-index: -2;

            .aura {
              --size: ac(863px, 500px);
            }
          }

          &:nth-child(1) {
            position: absolute;
            top: ac(82px, 100px);
            right: ac(52px, -200px);
            z-index: -1;

            .aura {
              --size: ac(863px, 500px);
            }
          }
        }
      }
    }
  }

  &.hiring-page {
    .blogs-section {
      &__wrap {
        .aura-container {
          &:nth-child(1) {
            position: absolute;
            top: ac(-376px, -80px);
            left: auto;
            right: ac(-99px, -200px);
            z-index: -2;
            clip-path: url('#my-clip-path');

            .aura {
              --size: ac(863px, 500px);
            }
          }
        }
      }
    }
  }
}
