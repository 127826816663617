.recruitment-section {
  padding-top: ac(99px, 40px);
  padding-bottom: ac(99px, 40px);
  overflow: visible;
  z-index: 15;

  .aura-container {
    position: absolute;
    top: ac(-155px, -200px);
    right: 20px;
    z-index: -1;

    .aura {
      --size: ac(1432px, 500px);
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(225, 17, 253, 0.4) 0%,
        rgba(225, 17, 253, 0) 100%
      );
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(76px, 32px);
    }

    @media (min-width: 1024px) {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 5;
    .swiper {
      width: 100%;
      max-width: 100%;
      overflow: visible;

      &:not(.swiper-initialized) {
        + .swiper-buttons {
          display: none;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(8, 7, 18, 0.9);
    padding-top: ac(32px, 24px);
    padding-bottom: ac(32px, 24px);
    padding-left: ac(32px, 20px);
    padding-right: ac(30px, 20px);
    border-radius: ac(20px, 16px);
    height: auto;
  }

  &__item-count {
    --size: ac(80px, 64px);
    width: var(--size);
    height: var(--size);
    border-radius: ac(20px, 16px);
    z-index: 1;
    position: absolute;
    top: ac(-18px, -10px);
    left: ac(-18px, -10px);
    background: var(--gradient);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cl-white);
  }

  &__item-title {
    padding-left: ac(56px, 40px);

    &:not(:last-child) {
      margin-bottom: ac(26px, 20px);
    }
  }

  &__item-text {
    max-height: 900px;
    overflow: auto;
    scrollbar-width: thin;
    padding-right: 6px;
  }
}
