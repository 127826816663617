.hiring-section {
  padding-top: ac(99px, 32px);
  padding-bottom: ac(120px, 40px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(-29px, -30px);
        left: ac(-363px, -200px);
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(17, 246, 238, 0.2) 0%,
            rgba(17, 246, 238, 0) 100%
          );
        }
      }

      &:nth-child(2) {
        position: absolute;
        bottom: ac(-322px, -50px);
        right: ac(-299px, -200px);
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(225, 17, 253, 0.4) 0%,
            rgba(225, 17, 253, 0) 100%
          );
        }
      }
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(40px, 30px);
    }
  }

  &__filters {
    &:not(:last-child) {
      margin-bottom: ac(60px, 30px);
    }

    .filters-slider {
      &__item {
        width: min-content;
        input {
          &:checked {
            + .filters-slider__item-toggle {
              color: var(--cl-black);

              &:before {
                background-color: var(--cl-blue);
              }
            }
          }
        }
      }

      &__item-toggle {
        &:before {
          border-color: var(--cl-blue);
        }

        &:hover {
          color: var(--cl-blue);

          &:before {
            border-color: var(--cl-pink);
          }
        }
      }
    }
  }

  &__tabs {
    position: relative;
    width: 100%;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.active {
      opacity: 1;
      pointer-events: auto;
      position: relative;
      top: auto;
      left: auto;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 48.71%;
  }

  &__item-title {
    color: var(--cl-blue);
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__item-image-container {
    position: relative;
    width: 46.78%;
  }

  &__item-image {
    --arw: 581;
    --arh: 436.01;
    mask-image: url('../../assets/static/mask/hiring-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    width: 100%;
  }

  &__item-decor {
    --arw: 660.84;
    --arh: 343.56;
    width: 113.74%;
    position: absolute;
    z-index: 1;
    left: -6.83%;
    bottom: -13.25%;
  }

  @media (max-width: 768px) {
    .hiring-section {
      &__item {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 24px;
      }

      &__item-content {
        width: 100%;
      }

      &__item-image-container {
        width: 100%;
        max-width: 500px;
      }
    }
  }
}
