.sectors-section {
  padding-top: ac(99px, 32px);
  padding-bottom: ac(125px, 40px);
  --sw-margin: ac(96px, 32px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      position: absolute;
      top: ac(-16px, -40px);
      right: ac(-121px, -200px);
      z-index: -1;

      .aura {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(225, 17, 253, 0.3) 0%,
          rgba(225, 17, 253, 0) 100%
        );
      }
    }
  }

  &__bg-decor {
    --arw: 1562;
    --arh: 813;
    width: 125.76%;
    left: -12.8%;
    position: absolute;
    z-index: -1;
    bottom: ac(-173px, -20px);
    min-width: 800px;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    position: relative;

    .swiper {
      margin-left: 0;
      width: 100%;
      max-width: ac(1030px, 1000px);
      overflow: visible;
    }

    .swiper-buttons {
      @media (min-width: 641px) {
        position: absolute;
        right: 0;
        top: calc(var(--sw-margin) * -1);
        transform: translateY(-100%);

        .swiper-button {
          margin-top: 0;
        }
      }
    }
  }

  &__item {
    padding-top: 12px;
    padding-left: 16px;
    position: relative;
    z-index: 1;

    &:hover {
      .sectors-section {
        &__item-image {
          img {
            opacity: 0.7;
          }
        }

        &__item-decor {
          opacity: 0.5;
        }

        &__item-title {
          color: var(--cl-blue);
        }
      }
    }
  }

  &__item-decor {
    --arw: 476;
    --arh: 371;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(98% - 16px);
    transition: 0.3s;
    border-radius: ac(20px, 16px);
  }

  &__item-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-radius: ac(16px, 12px);
    clip-path: inset(1px 1px 1px 1px round ac(16px, 12px));
    overflow: hidden;
    width: 100%;
    transform: translateZ(0);
  }

  &__item-image {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &__item-bg {
    --arw: 484;
    --arh: 363;
    width: 100%;
    z-index: 1;
  }

  &__item-content {
    position: absolute;
    inset: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(48px, 24px);
    padding-left: ac(40px, 20px);
    padding-right: ac(40px, 20px);
    padding-bottom: ac(32px, 24px);
  }

  &__item-title {
    color: var(--cl-black);
    transition: 0.3s;
    line-height: 1.33;
    &.heading-30 {
      @media (max-width: 451px) {
        font-size: 18px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__item-buttons {
    margin-top: auto;
    width: 100%;
    justify-content: flex-end;

    @media (max-width: 551px) {
      align-items: flex-start;
    }
  }
}
