.services-section {
  padding-top: ac(0px, 32px);
  padding-bottom: ac(70px, 40px);
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      &:nth-child(1) {
        left: ac(-132px, -311px);
        bottom: ac(-280px, 40px);
        position: absolute;
        z-index: -1;

        .aura {
          background: radial-gradient(50% 50% at 50% 50%, rgba(17, 246, 238, 0.5) 0%, rgba(17, 246, 238, 0) 100%);
          --size: ac(853px, 752px);
        }
      }

      &:nth-child(2) {
        right: ac(52px, -392px);
        bottom: ac(-518px, -493px);
        position: absolute;
        z-index: -2;

        .aura {
          background: radial-gradient(50% 50% at 50% 50%, rgba(225, 17, 253, 0.3) 0%, rgba(225, 17, 253, 0) 100%);
        }

        @media (max-width: 851px) {
          min-width: 918px;

          .aura {
            --size: ac(853px, 918px);
          }
        }
      }
    }
  }

  &__list {
    width: 100%;

    @media (min-width: 701px) {
      --arw: 1242;
      --arh: 514;
      position: relative;

      &::before {
        content: '';
        display: block;
        padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
      }

      .services-section {
        &__item {
          position: absolute;

          &:nth-child(1) {
            left: 0;
            top: 14.79%;
          }

          &:nth-child(2) {
            left: 18.28%;
            bottom: 0;
            z-index: 1;
          }

          &:nth-child(3) {
            left: 38.89%;
            top: 24.51%;
          }

          &:nth-child(4) {
            left: 61.11%;
            top: 31.71%;
            z-index: 1;
          }

          &:nth-child(5) {
            right: 0;
            top: 0;
          }
        }
      }

      @media (max-width: 1400px) {
        --arh: 614;

        .services-section {
          &__item {
            &:nth-child(2) {
              left: 15%;
            }

            &:nth-child(3) {
              left: 35%;
            }

            &:nth-child(4) {
              left: 58%;
            }
          }
        }
      }

      @media (max-width: 1200px) {
        --arh: 614;

        .services-section {
          &__item {
            &:nth-child(3) {
              top: 21%;
            }

            &:nth-child(4) {
              top: 38%;
            }
          }
        }
      }

      @media (max-width: 1100px) {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
      }

      @media (max-width: 801px) {
        --arh: 714;

        .services-section {
          &__item {
            &:nth-child(3) {
              top: 24%;
            }

            &:nth-child(4) {
              top: 40%;
              left: 60%;
            }
          }
        }
      }

      @media (max-width: 760px) {
        .services-section {
          &__item {
            &:nth-child(3) {
              top: 16%;
            }
          }
        }
      }
    }

    @media (max-width: 700px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      max-width: 350px;

      .services-section {
        &__item {
          &:nth-child(2n) {
            margin-left: auto;
          }

          &:not(:first-child) {
            margin-top: -76px;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(0px, 40px);
    }
  }

  &__item {
    border-radius: 50%;
    --size: ac(288px, 200px);
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    text-align: center;
    position: relative;
    text-transform: uppercase;
    background: transparent;
    transition: 0.3s;
    overflow: hidden;

    &:hover {
      z-index: 10 !important;
      background: rgba(255, 255, 255, 0.14);
    }
  }

  &__item-title {
    &.heading-30 {
      font-size: ac(30px, 18px);
    }
  }

  &__buttons {
    position: relative;
    z-index: 10;

    @media (min-width: 1400px) {
      margin-top: -21px;
    }
  }
}
