:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @media (max-width: 640px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }
      .meta-input {
        input {
          @mixin transparent-bg-input var(--cl-white);
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;
        .meta-checkbox__box {
          .check-clip {
            height: 13px;
          }
        }
        .meta-checkbox__text {
          font-family: var(--font-main);
          font-weight: 500;
          font-size: 14px;
          line-height: 1.56;
          display: flex;
          align-items: flex-end;

          a {
            color: var(--cl-blue);
            text-decoration: none;
            transition: 0.3s;
            width: max-content;
            display: contents;

            &:hover {
              color: var(--cl-pink);
            }
          }
        }

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__block {
    &.project-popup {
      background: var(--cl-black);
      border-radius: ac(40px, 24px);
      border: 1px solid rgba(17, 246, 238, 0.3);
      position: relative;
      max-width: ac(1024px, 800px);
      padding: 0 ac(96px, 32px) 0 ac(56px, 20px);
      color: var(--cl-white);

      .article-section__share {
        padding-bottom: ac(36px, 16px);
      }

      .project-popup {
        &__bg {
          position: absolute;
          inset: 0;
          border-radius: inherit;
          overflow: hidden;
          z-index: 1;
        }

        &__bg-decor {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          min-width: 1592px;
          min-height: 1289px;
          transform: translateX(-50%) translateY(-50%);
          z-index: 2;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__bg-image {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: ac(512px, 280px);
          z-index: 1;
          overflow: hidden;

          &:after {
            position: absolute;
            inset: 0;
            left: -1px;
            background: linear-gradient(
              90deg,
              #080712 0%,
              rgba(8, 7, 18, 0.6) 50%,
              rgba(8, 7, 18, 0.6) 100%
            );
            content: '';
            z-index: 1;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(100%);
          }
        }

        &__btn-close {
          right: ac(24px, 10px);
          top: ac(24px, 10px);
          z-index: 20;
          --size: ac(48px, 30px);
          border-radius: 50%;
          border: 1px solid var(--cl-white);
          width: var(--size);
          height: var(--size);
          background: transparent;

          .close-clip {
            transition: inherit;
            width: ac(18px, 16px);
            height: ac(18px, 16px);
            background: var(--cl-white);
          }

          i {
            color: var(--cl-white);
            font-size: ac(18px, 16px);
          }

          &:hover {
            border-color: var(--cl-pink);
            background-color: var(--cl-pink);

            .close-clip {
              background-color: var(--cl-blue);
              transform: scale(1);
            }

            i {
              color: var(--cl-blue);
            }
          }

          @media (max-width: 451px) {
            top: 0;
            right: 0;
          }
        }

        &__content {
          z-index: 10;
          width: calc(100% + ac(51px, 16px));
          padding-right: ac(51px, 16px);
          padding-top: ac(48px, 32px);
          padding-bottom: 20px;
          mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) ac(48px, 32px),
            rgba(0, 0, 0, 1) calc(100% - ac(48px, 20px)),
            rgba(0, 0, 0, 0) 100%
          );

          @media (min-width: 1025px) {
            max-height: calc(var(--vh, 1vh) * 77);

            @media (min-height: 900px) {
              max-height: 700px;
            }
          }

          .simplebar-track.simplebar-vertical {
            width: 3px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 4px;
            transform: none;
            top: ac(108px, 64px);
            bottom: ac(56px, 24px);
            overflow: visible;
            right: 3px;

            .simplebar-scrollbar {
              background: var(--cl-blue);
              border-radius: 4px;
              width: 6px;
              left: -1.5px;

              &:before {
                content: none;
              }
            }
          }
        }

        &__wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
        }

        &__top-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          grid-column-gap: ac(20px, 10px);
          padding-right: ac(30px, 20px);

          .project-popup__top-title {
            font-size: ac(40px, 28px);
          }

          &:not(:last-child) {
            margin-bottom: ac(48px, 24px);
          }
        }

        &__title {
          &:not(:last-child) {
            margin-bottom: ac(48px, 24px);
          }
        }

        &__top-icon {
          --arw: 1;
          --arh: 1;
          width: ac(88px, 40px);
          min-width: ac(88px, 40px);
        }

        &__top-logo {
          width: ac(219px, 100px);
          min-width: ac(219px, 100px);
          height: ac(123px, 80px);
          padding-left: ac(33px, 10px);
          padding-right: ac(33px, 10px);
          padding-top: 10px;
          padding-bottom: 10px;
          border-radius: ac(12px, 8px);
          background: var(--cl-white);
          box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &__top-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          grid-row-gap: 8px;
          padding-left: ac(12px, 2px);
        }

        &__text {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          &.content-element {
            h2,
            h3,
            h4 {
              color: var(--cl-blue);
            }

            > * {
              &:last-child {
                padding-bottom: ac(20px, 12px);
              }
            }

            p,
            ul,
            ol {
              + h2 {
                padding-top: ac(16px, 8px);
              }

              + h3 {
                padding-top: ac(16px, 8px);
              }

              + h4 {
                padding-top: ac(16px, 8px);
              }
            }

            h2 {
              font-size: ac(30px, 22px);
              font-weight: 900;
              line-height: 1.23;
              font-family: var(--font-second);
              text-transform: uppercase;

              &:not(:last-child) {
                margin-bottom: ac(28px, 22px);
              }
            }

            h3 {
              font-size: ac(25px, 20px);
              font-weight: 900;
              line-height: 1.32;
              font-family: var(--font-second);
              text-transform: uppercase;

              &:not(:last-child) {
                margin-bottom: ac(24px, 20px);
              }
            }

            h4 {
              font-size: ac(20px, 18px);
              font-weight: 900;
              line-height: 1.32;
              font-family: var(--font-second);
              text-transform: uppercase;

              &:not(:last-child) {
                margin-bottom: ac(24px, 20px);
              }
            }

            ul {
              li {
                padding-left: ac(38px, 24px);
                &:before {
                  background-color: var(--cl-blue);
                  top: ac(9.5px, 7px);
                  --size: ac(12px, 10px);
                }
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(28px, 14px);
          }
        }
      }

      &.study-popup {
      }

      &.team-popup {
        .project-popup {
          &__container {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            grid-column-gap: ac(40px, 20px);
          }

          &__btn-close {
            @media (max-width: 451px) {
              top: 0;
              right: 0;
            }
          }

          &__card {
            min-width: ac(394px, 300px);
            width: ac(394px, 300px);
            display: flex;
            align-items: stretch;
            justify-content: stretch;
            margin-left: ac(-57px, -21px);
            margin-top: -1px;
            margin-bottom: -1px;

            .team-card {
              width: 100%;
              height: 100%;
              border-radius: ac(40px, 24px) 0 0 ac(40px, 24px);
              border-color: rgba(255, 255, 255, 0.4);
              cursor: auto;

              &__image {
                min-height: 395px;
                margin-bottom: -90px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: bottom;
                }
              }

              &__content {
                mask-image: url('../../assets/static/mask/team-card-mask-popup.png');
                mask-size: 100% 100%;
                mask-repeat: no-repeat;
              }
            }

            @media (max-width: 767px) {
              display: none;
            }
          }

          &__content {
            @media (min-width: 768px) {
              padding-top: 48px;
              width: unset;
              flex: 1;
              margin-right: ac(-51px, -16px);
              mask-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 1) ac(48px, ac(40px, 32px)),
                rgba(0, 0, 0, 1) calc(100% - ac(50px, 20px)),
                rgba(0, 0, 0, 0) 100%
              );
            }

            @media (max-width: 767px) {
              width: unset;
              flex: 1;
              margin-right: -32px;
              max-width: calc(100% + 32px);
              padding-left: 20px;
            }

            @media (min-width: 1025px) {
              max-height: calc(var(--vh, 1vh) * 70);

              @media (min-height: 900px) {
                max-height: 632px;
              }
            }
          }

          &__text {
            /*@media (min-width: 768px) {
              margin-top: 48px;
            }*/
          }

          &__card-mob {
            display: flex;
            align-items: stretch;
            justify-content: stretch;
            flex: 1;
            max-width: 300px;
            margin-left: -21px;
            margin-top: -32px;

            .team-card {
              width: 100%;
              height: 100%;
              border-radius: 24px 0 16px 0;
              border-color: rgba(255, 255, 255, 0.4);
              cursor: auto;

              @media (min-width: 768px) {
                display: none;
              }

              @media (max-width: 360px) {
                margin-top: 64px;
              }
            }

            &:not(:last-child) {
              margin-bottom: ac(24px, 20px);
            }
          }
        }

        @media (max-width: 768px) {
          padding-left: 0;
        }
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
