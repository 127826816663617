.purpose-section {
  padding-top: ac(99px, 40px);
  padding-bottom: ac(99px, 40px);

  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .aura-container {
      position: absolute;
      bottom: ac(-263px, -100px);
      right: ac(-121px, -90px);
      z-index: -1;

      .aura {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(17, 246, 238, 0.4) 0%,
          rgba(225, 17, 253, 0) 100%
        );
      }
    }
  }

  &__bg-container {
    --arw: 1540;
    --arh: 800;
    position: absolute;
    z-index: -1;
    right: -100px;
    top: ac(-42px, -24px);
    width: 123.99%;

    min-width: 800px;

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }
  }

  &__bg-decor {
    --arw: 599.59;
    --arh: 341.5;
    position: absolute;
    z-index: 3;
    width: 38.93%;

    &:nth-child(1) {
      top: 17.43%;
      right: 19.57%;

      @media (max-width: 1023px) {
        top: 100px;
        right: -50px;
        min-width: 350px;
      }
    }

    &:nth-child(2) {
      top: 33.42%;
      right: 48.14%;

      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__image-container {
    --arw: 1540.08;
    --arh: 800;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    mask-image: linear-gradient(
      to bottom,
      rgba(8, 7, 18, 1) 0%,
      rgba(8, 7, 18, 0) 84.88%,
      rgba(8, 7, 18, 0) 100%
    );

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
    }
  }

  &__image-decor {
    --arw: 1540.08;
    --arh: 800;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__image {
    --arw: 1540.08;
    --arh: 800;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    mask-image: url('../../assets/static/mask/purpose-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    .swiper {
      width: 100%;
      max-width: 100%;
      overflow: visible;

      @media (max-width: 551px) {
        margin-left: 0;
        max-width: 304px;
      }
    }

    .swiper-wrapper {
      @media (min-width: 1024px) {
        align-items: flex-end;

        .purpose-section {
          &__item {
            min-height: ac(566px, 500px);

            &:nth-child(1) {
              min-height: calc(ac(566px, 500px) - ac(240px, 120px));
            }

            &:nth-child(2) {
              min-height: calc(ac(566px, 500px) - ac(120px, 60px));
            }
          }
        }
      }
    }
  }

  &__item {
    background: var(--cl-black);
    border: 2px solid var(--cl-blue);
    padding: ac(31px, 24px) ac(31px, 20px);
    min-height: ac(326px, 300px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: ac(20px, 16px);
    overflow: hidden;

    @media (max-width: 1023px) {
      height: auto;
    }
  }

  &__item-title {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }
}
