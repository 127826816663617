.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(44px, 40px);
  min-width: ac(44px, 40px);
  background: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 0 ac(16px, 6px);
  i {
    font-size: 20px;
    color: var(--cl-white);
    transition: color 0.3s ease;
  }

  &.pagination-btn-disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    &:not(.pagination-btn-disabled) {
      border-color: #393941;
    }
  }

  /*&.pagination-next {
    margin-left: ac(16px, 8px);
  }

  &.pagination-prev {
    margin-right: ac(16px, 8px);
  }*/
}

.pagination-num {
  text-align: center;
  font-weight: 400;
  font-size: ac(20px, 18px);
  line-height: 1.48;
  color: #fff;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(48px, 40px);
  min-width: ac(48px, 40px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    color: var(--cl-white);
    transition: all 0.3s ease;
  }

  &.active {
    cursor: not-allowed;
    background: #393941;
    span {
    }
  }

  &.pointer-events-none {
    pointer-events: none;
    min-width: ac(48px, 40px);
    width: ac(48px, 40px);
    color: #6b6b71;
  }

  &:hover {
    &:not(.active) {
      span {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.pagination {
  margin-top: ac(40px, 35px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
}
