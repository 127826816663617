.job-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  min-height: 357px;
  position: relative;
  padding: ac(40px, 44px) 40px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;

  &:before {
    position: absolute;
    z-index: 10;
    inset: 20px;
    border: 1px solid var(--cl-white);
    pointer-events: none;
    border-radius: 15px;
    content: '';
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 17px;
    }

    @media (max-width: 551px) {
      &.heading-25 {
        font-size: 24px;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;

    li {
      font-size: ac(15px, 13px);
      font-weight: 900;
      line-height: 1.27;
      font-family: var(--font-second);
      text-transform: uppercase;
      padding: 5px 7px;
      border-radius: 15px;
      background: var(--cl-pink);
    }

    &:not(:last-child) {
      margin-bottom: 17px;
    }
  }

  &__btn {
    margin-top: auto;
    margin-left: auto;
    --size: 36px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(57, 57, 65, 0.5);
    border-radius: 10px;
    transition: 0.3s;

    i {
      font-size: 19px;
    }

    &:hover {
      border-color: var(--cl-blue);
      background-color: var(--cl-pink);
    }
  }
}
