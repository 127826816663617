.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-pink) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-black);

  color: var(--cl-white);
  font-family: var(--font-main);
  font-size: ac(20px, 18px);
  font-weight: 400;
  line-height: 1.5;

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  scrollbar-width: thin;
  scrollbar-color: var(--cl-pink) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-blue);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-pink);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &:not(.is-visible) {
    overflow: hidden;
  }

  &.is-visible {
    overflow: clip;
    @media (max-width: 640px) {
      overflow: hidden;
    }
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1242px;
  width: perc(1242);

  @media (max-width: 1100px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 373px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

section,
footer,
header {
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.swiper {
  width: 100%;
  max-width: 100%;
}

.sections-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
}

.title-animation {
  opacity: 0;

  /*.first-section__title-line {
    &:after {
      transition: 0.5s;
      opacity: 0;
    }
  }*/

  &.animated {
    opacity: 1;

    .gradient-text {
      &:after {
        animation: slide-in 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
        animation-delay: calc(100ms * var(--word-index));
      }
    }

    .splitting .word {
      animation: slide-in 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(100ms * var(--word-index));
      /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
    }
  }
}

.second-title-animation {
  transition: all 0.3s ease;
  opacity: 0;
  transform: scaleX(1.1) translateX(5%);
  transform-origin: top left;

  &.animated {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-0.5em) scaleY(1.2) rotate(0);
    opacity: 0;
    /*clip-path: polygon(110% 80%, 110% 100%, 0 100%, 0 80%);*/
  }
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }
}

[data-scroll] {
  width: calc(100% + 10px);
  padding-right: 10px;

  .os-theme-dark,
  .os-theme-light {
    --os-size: ac(8px, 6px);
    --os-track-border-radius: 6px;
  }

  .os-theme-dark {
    --os-handle-bg: var(--cl-blue);
    --os-handle-bg-hover: var(--cl-pink);
  }
}

.image-aspect-ratio {
  position: relative;
  overflow: hidden;
  mask-image: radial-gradient(white, black);

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
  }

  img,
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  &[data-parallax-el] {
    overflow: visible;
    mask-image: none;

    img,
    svg {
      position: absolute !important;
    }
  }
}

.border-gradient-block {
  position: relative;
  z-index: 1;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background: var(--gradient);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    border-radius: inherit;
  }
}

.max-line-length-number {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-line-num);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.aura-container {
  pointer-events: none;
}

.aura {
  --size: ac(863px, 415px);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(225, 17, 253, 0.5) 0%,
    rgba(17, 246, 238, 0) 100%
  );

  &.aura-blue {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(17, 246, 238, 0.5) 0%,
      rgba(17, 246, 238, 0) 100%
    );
  }

  &.aura-blue-light {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(17, 246, 238, 0.5) 0%,
      rgba(17, 246, 238, 0) 100%
    );
  }
}

.pink-color {
  color: var(--cl-pink);
}
.blue-color {
  color: var(--cl-blue);
}

.cookie-terms-section {
  padding-top: ac(192px, 120px);
  padding-bottom: ac(84px, 54px);
}
.success-title {
  font-size: ac(32px, 20px, 640);
}
