.ready-section {
  margin-top: ac(41px, 24px);
  padding-top: ac(48px, 32px);
  padding-bottom: ac(48px, 32px);
  margin-bottom: ac(41px, 24px);
  overflow: hidden;

  .cont {
    @media (min-width: 1101px) {
      margin: 0 auto;
      max-width: 1344px;
      width: perc(1344);
    }
  }

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    border-radius: ac(24px, 20px);
    background: var(--cl-black);
    z-index: 1;

    &:before {
      position: absolute;
      z-index: 5;
      inset: -1px;
      content: '';
      border-radius: ac(24px, 20px);
      border: ac(10px, 5px) solid var(--cl-black);
      pointer-events: none;
    }
  }

  &__content {
    padding-top: ac(63px, 40px);
    padding-bottom: ac(64px, 40px);
    padding-left: ac(80px, 24px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    width: 45.58%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-transform: uppercase;

    &.heading-60 {
      line-height: 1.2;

      @media (max-width: 451px) {
        font-size: 28px;
      }

      @media (max-width: 360px) {
        font-size: 24px;
      }
    }

    h1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &[data-view] {
      .ready-section__title-line {
        i {
          transform: scaleX(-1) translateY(-50%) rotate(30deg);
          transition: 0.5s;
          opacity: 0;
        }
      }

      &.animated {
        .ready-section__title-line {
          i {
            transform: scaleX(-1) translateY(-4px) rotate(0deg);
            opacity: 1;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__title-line {
    white-space: nowrap;
    i {
      color: var(--cl-pink);
      font-size: ac(53px, 24px);
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      transform: translateY(ac(-3px, -2px));
      margin-right: ac(10px, 8px);

      @media (max-width: 360px) {
        transform: translateY(-1px);
        font-size: 20px;
      }
    }
  }

  &__buttons {
    grid-column-gap: 16px;
  }

  &__decor {
    --arw: 660.84;
    --arh: 343.56;
    width: 53.21%;
    position: absolute;
    left: ac(-88px, -102px, 1024);
    bottom: ac(-196px, -100px);
    max-width: 660px;

    @media (max-width: 1023px) {
      left: -120px;
      bottom: -150px;
    }
  }

  &__image-container {
    width: 55.42%;
    position: relative;
  }

  &__image-decor {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image {
    --arw: 731.98;
    --arh: 602;
    width: 100%;
    position: relative;
    z-index: 1;

    img {
      object-fit: contain;
      object-position: bottom;
    }
  }

  @media (max-width: 851px) {
    .ready-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 32px;
      }

      &__content {
        width: 100%;
        padding-right: 24px;
      }

      &__image-container {
        margin-left: auto;
        margin-top: -100px;
      }
    }
  }

  @media (max-width: 690px) {
    .ready-section {
      &__image-container {
        margin-left: auto;
        margin-top: 0;
        width: 100%;
      }

      &__content {
        padding-bottom: 0;
      }

      &__decor {
        display: none;
      }
    }
  }

  &.hiring-page {
    margin-bottom: ac(24px, 20px);
    .ready-section {
      &__title {
        .icon-title-decor {
          transform: scaleX(-1) translateY(0px) rotate(0deg)!important;
        }
      }
      &__title-line {
        i {
          color: var(--cl-blue);
        }
      }
    }
  }
}
