/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: 1px solid var(--cl-primary);
  border-right: 0;
  border-bottom: 0;
  background: var(--cl-black);

  color: var(--cl-white);
  font-family: var(--font-main);
  border-radius: 20px 0 0 0;

  .cc-nb-okagree,
  .cc-nb-reject {
    text-transform: capitalize;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--cl-white);
  }

  .cc-nb-title {

  }

  #cc-nb-text {
    font-weight: 300;
  }
  #cc-nb-title {
    font-family: var(--font-second);
    font-size: acac(38px, 24px);
    font-weight: 900;
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
    padding: 13px 27px;
    transition: all 0.3s ease;
    background: var(--cl-white);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background: var(--cl-purple);
    border: 1px solid transparent;
    gap: 8px;
    height: 46px;
    font-size: 16px;
    font-weight: 800;
    line-height: 126%;
    color: var(--cl-white);
    text-transform: capitalize !important;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:hover {
      background: var(--cl-white);
      color: var(--cl-purple);
    }
  }

  .cc-nb-okagree, .cc-nb-reject, .cc-nb-changep {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-second);
    font-weight: 800;
    font-size: ac(17px, 15px);
    text-align: center;
    padding: 5px 19px;
    min-height: ac(52px, 48px);
    transition: 0.4s;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    grid-column-gap: 10px;

    border: 1px solid transparent;
    background: transparent;
    color: var(--cl-black);
    z-index: 1;
  
    &:before {
      inset: 0;
      content: '';
      position: absolute;
      transition: 0.4s;
      z-index: -1;
      clip-path: circle(0px at 50% 50%);
      background: linear-gradient(45deg, #e111fd 0%, var(--cl-black) 100%);
      opacity: 0.5;
    }

    &:after {
      inset: 0;
      content: '';
      background: linear-gradient(101.59deg, #e111fd 0%, #11f6ee 100%);
      position: absolute;
      transition: 0.3s;
      z-index: -2;
    }
  
    i {
      font-size: ac(32px, 30px);
    }
  
    &:hover {
      border-color: var(--cl-black);
      color: var(--cl-white);
      /* &:before {
        opacity: 1;
        clip-path: circle(75%);
      } */
    }


  }

  .cc-nb-changep {
    position: relative;
    z-index: 1;
    border-color: transparent;
    background: transparent;
    color: var(--cl-white);
    margin: 0!important;
    &:after {
      position: absolute;
      inset: 0;
      content: '';
      z-index: -1;
      background: var(--gradient);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      padding: 2px;
      border-radius: inherit;
    }

    &:before {
      background: linear-gradient(101.59deg, #e111fd 0%, #11f6ee 100%);
    }

    &:hover {
      color: var(--cl-black);

      &:before {
        opacity: 1;
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    gap: ac(16px, 12px);
    grid-row-gap: 12px;
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: 1px solid var(--cl-primary);
    border-radius: 18px;
    overflow: hidden;
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--cl-black) !important;
    border-top-color: var(--cl-primary) !important;
    border-bottom-color: var(--cl-primary) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
    border-right: 1px solid var(--cl-primary);
  }

  .cc-cp-body-tabs {
    background-color: var(--cl-black);
  }

  .cc-cp-body-tabs-item {
    border-color: var(--cl-primary) !important;
    background: var(--cl-black);
  }

  .cc-cp-body-tabs-item-link {
    color: var(--cl-white);
    transition: all .3s ease;

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--cl-purple) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-black) !important;
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-black);
      padding: 5px;
      background: var(--cl-white);
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.6;
        letter-spacing: -0.1px;
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--cl-purple);
    transition: all 0.3s ease;

    &:hover {
      color: var(--cl-primary);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox:checked + label::before {
  background-color: var(--cl-primary);
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--cl-purple);
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        color: var(--cl-white);
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    text-align: center;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    padding: 13px 27px;
    transition: all 0.3s ease;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background: var(--cl-primary);
    border: 2px solid var(--cl-purple);
    gap: 8px;
    height: 46px;
    font-size: 16px;
    font-weight: 800;
    line-height: 126%;
    color: var(--cl-white);
    text-transform: capitalize !important;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:hover {
      background-color: var(--cl-black);
    }

    @media (max-width: 640px) {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
    color: var(--cl-purple);
    text-decoration: underline;
    text-decoration: var(--cl-purple);
    transition: all .3s ease;

    &:hover {
      color: var(--cl-white);
      text-decoration: var(--cl-white);
    }
  }
}

/*
Styles for mobile adaptation
*/
@mixin max-sm {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 15px;
  }
  .termsfeed-com---nb .cc-nb-changep {
    font-size: 13.5px!important;
    padding: 5px 10px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
}
