.meta-input,
.meta-select,
.meta-upload-file {
  label,
  .label {
    font-family: var(--font-md-bt);
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    color: var(--cl-white);
    padding-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-red);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-weight: 400;
    font-size: ac(17px, 16px);
    line-height: 1.56;
    font-family: var(--font-main);
    color: var(--cl-white);
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 15px 8px 15px 20px;
    height: ac(56px, 50px);
    background: var(--cl-dark-grey);
    @mixin transparent-bg-input var(--cl-primary);
    border-radius: 10px;
    border: 1px solid #11f6ee80;

    z-index: 1;

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-white);
    }

    &:focus {
      border-color: var(--cl-primary);
      caret-color: var(--cl-primary);
      box-shadow: 3px 3px 5px 0px rgba(126, 207, 174, 0.3);
    }

    /*&.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }*/
  }

  textarea {
    font-size: 17px;
    min-height: ac(112px, 90px) !important;
    max-height: ac(112px, 90px);
    padding: 15px 16px;
    overflow-y: auto;
    resize: none;

    scrollbar-width: thin;
    scrollbar-color: var(--cl-blue) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-blue);
      border-radius: 2px;
    }
  }

  &.dark-border {
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 700;
  }

  &__help {
    color: var(--cl-white);
  }

  /*&__error {
    color: var(--cl-red);
  }*/

  &__date-container {
    position: relative;
    width: 100%;

    input {
      padding-right: ac(44px, 38px);
    }

    i {
      position: absolute;
      top: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      right: ac(20px, 19px);
      font-size: 9px;
      color: var(--cl-primary);
      transform: translateY(-50%);
      transition: 0.3s;
      z-index: 1;
    }

    input {
      &:focus {
        + i {
          transform: translateY(-50%) scaleY(-1);
        }
      }
    }
  }

  &.shadow-type {
    input {
      box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);
      font-size: 18px;
    }
  }

  &.second-type {
    border-bottom: 1px solid var(--cl-blue);
    display: flex;
    padding-bottom: ac(16px, 6px);
    flex-direction: row;
    height: ac(69px, 59px);
    max-height: ac(69px, 59px);

    input {
      color: #fff;
      font-weight: 400;
      font-size: ac(22px, 18px);
      line-height: 1.48;
      border: none;
      height: ac(50px, 48px);
      padding: 0;
      border-radius: 0;
      padding-right: 8px;
      background: transparent;
      font-family: var(--font-main);
      @mixin transparent-bg-input var(--cl-white);
      &:focus {
        caret-color: var(--cl-white);
        box-shadow: none;
      }
      &::placeholder {
        color: white;
        opacity: 1;
      }
    }

    @media (max-width: 450px) {
      height: auto;
      max-height: unset;

      input {
        height: 40px;
        min-height: 40px;
      }
    }
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  /*label,
  .label {
    font-family: var(--font-main);
    color: var(--cl-leaf);
    font-size: ac(14px, 12px);
    font-weight: 700;
    padding-bottom: ac(13px, 6px);
    cursor: pointer;
  }*/

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(28px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(16px, 12px);
    }
  }

  &__text {
    font-weight: 400;
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    font-family: var(--font-second);
    color: var(--cl-white);
    border: 1px solid var(--cl-pink);
    border-radius: 28px;
    background: #d4d4d4;
    transition: all 0.3s ease;
    padding: 4px ac(19px, 15px);
    cursor: pointer;
    position: relative;
    height: 30px;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--cl-black);
    }

    &:hover {
      border-color: var(--cl-pink);
      background-color: var(--cl-pink);
      color: var(--cl-white);
    }

    &:not(:last-child) {
      margin-bottom: ac(20px, 14px);
    }
  }

  &__file-name {
    max-width: calc(100% - ac(120px, 114px));
    @mixin max-line-length-one;
    font-size: ac(18px, 16px);
    font-weight: 300;
    font-family: var(--font-second);
    color: var(--cl-white);
  }

  &__info {
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    font-family: var(--font-second);
    font-style: italic;
    color: var(--cl-white);
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-primary);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-primary) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.24s all;
  &:hover {
    opacity: 0.9;
  }
  &__box {
    --size: ac(28px, 24px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    border: 1px solid #6b6b71;
    margin-right: ac(12px, 10px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-pink);
    }

    .check-clip,
    .circle-clip {
      width: 15px;
      height: 11px;
      background-color: var(--cl-white);
      opacity: 0;
      transition: opacity 0.3s ease, background-color 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      font-size: ac(14px, 13px);
      color: var(--cl-white);
      transition: opacity 0.3s ease, color 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 5px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-family: var(--font-main);
    color: var(--cl-white);
    font-weight: 400;
    font-size: ac(20px, 18px);
    line-height: 1.48;
    color: #fff;
    a {
      font: inherit;
      color: var(--cl-pink);
      text-decoration: underline;
      text-decoration-color: var(--cl-pink);
      transition: all 0.25s ease;
      &:hover {
        color: var(--cl-white);
        text-decoration-color: var(--cl-white);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    background: var(--cl-pink);
    border-color: var(--cl-pink);
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
      color: var(--cl-white);
    }
    &:hover {
      border-color: var(--cl-purple);
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: visible;

  select {
    opacity: 0;
    height: ac(56px, 50px);
  }

  &__container {
    border-radius: 30px;
    width: 100%;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    &:not(.is-open) {
      overflow: hidden;
    }

    &__inner {
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 8px ac(19px, 18px) !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      height: ac(60px, 50px);
      background: transparent;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-family: var(--font-second);
      color: var(--cl-primary);
      border: 1px solid var(--cl-white);
      border-radius: 30px !important;
      outline: none;

      /*&:hover {
        border-color: var(--cl-orange);
      }*/
      /*&.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }*/
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: 8px;
        }
      }

      &.is-flipped {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 8px;
        }
      }
    }

    &__item {
      @mixin max-line-length-one;
    }

    &__placeholder {
      color: var(--cl-primary);
      opacity: 1;
      background: transparent;
    }

    &__list {
      padding: 0;
      background-color: var(--cl-white);

      .choices__item {
        padding-right: ac(36px, 30px);
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-family: var(--font-main);
        color: var(--cl-primary);
        transition: all 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;

        &.is-selected {
          color: var(--cl-primary);
        }
      }

      &--single {
        background-color: transparent !important;

        .choices__item {
          color: var(--cl-primary);
        }
      }

      &--multiple {
        color: var(--cl-primary);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        .choices__item {
          color: var(--cl-primary);
          text-decoration: none !important;

          &:hover {
            &:not(.is-selected) {
              color: var(--cl-purple) !important;
              text-decoration: none !important;
            }
          }
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        border-bottom: 1px solid var(--cl-black);
        border-right: 1px solid var(--cl-black);
        content: '';
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: ac(25px, 18px);
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg) scale(1.5);
        transition: all 0.15s ease-in-out;
        width: 5px;
      }
    }

    &.is-open {
      &:after {
        transform: rotate(-135deg) scale(1.5);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-primary);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 1px solid var(--cl-primary);
      z-index: 20;
      border-radius: 0;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 1px solid var(--cl-primary);
      border-radius: 0;
      background: var(--cl-white);
      color: var(--cl-primary);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-second);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  &.second-type {
    .choices {
      &__inner {
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid var(--cl-black) !important;
        display: flex;
        padding: 0 0 ac(16px, 6px) 0;
        flex-direction: row;
        height: ac(69px, 59px);
        max-height: ac(69px, 59px);
        background: transparent;
        font-family: var(--font-main);
        color: #fff;
        font-weight: 400;
        font-size: ac(22px, 18px);
        line-height: 1.48;
      }

      &__item {
        color: var(--cl-black);
        font-weight: 400;
        font-size: ac(22px, 18px);
        line-height: 1.48;
        &--selectable {
          color: #fff;
          font-weight: 400;
          font-size: ac(22px, 18px);
          line-height: 1.48;
          color: var(--cl-black);
        }
      }
    }
  }

  &.sort-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    grid-column-gap: ac(28px, 18px);

    label {
      padding-bottom: 0;
      text-transform: none;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-family: var(--font-md-bt);
    }

    .meta-select__container {
      width: auto;
      min-width: 181px;
    }

    .choices {
      &__inner {
        padding: 5px 13px !important;
        width: 100%;
        height: 36px;
        background: transparent;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: var(--font-md-bt);
        color: var(--cl-primary);
      }

      &__list--single {
        .choices__item {
          font-weight: 500;
          font-family: var(--font-md-bt);
        }
      }

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        cursor: pointer;
        &:after {
          min-width: 11px;
          min-height: 6px;
          content: '\e91a';
          right: ac(14px, 12px);
          font-size: 6px;
          color: var(--cl-purple);
        }
      }
    }
  }
}

.default-custom-select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .label {
    font-family: var(--font-md-bt);
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    color: var(--cl-primary);
    padding-bottom: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-red);
    }
  }

  .default-custom-select {
    width: 100%;
  }
}

.default-custom-select {
  display: inline-flex;
  padding: 8px ac(19px, 18px) !important;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ac(60px, 50px);
  min-height: ac(60px, 50px);
  background: var(--cl-white);
  border: 1px solid var(--cl-white);
  border-radius: 30px;
  transition: all 0.25s ease;
  position: relative;
  cursor: pointer;
  z-index: 20;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);

  &__placeholder {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: var(--font-second);

    color: var(--cl-primary);
    @mixin max-line-length-one;
  }

  &__list {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px solid var(--cl-primary);
    margin-top: -1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    bottom: -8px;
    left: -1px;
    right: -1px;
    transform: translateY(100%);
    z-index: 100;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 8px;
    cursor: default;
    padding: 10px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }

  &.active {
    .default-custom-select__list {
      display: flex;
    }
  }

  &:before {
    border: none;
    content: '\e900';
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    position: absolute;
    right: ac(20px, 19px);
    top: 50%;
    transition: all 0.15s ease-in-out;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-top: 0;
    z-index: 2;
    font-size: 9px;
    color: var(--cl-primary);
    transform: translateY(-50%);

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.active {
    &:before {
      transform: translateY(-50%) scaleY(-1);
    }
  }
}
