.text-decor-section {
  padding-top: ac(92px, 38px);
  padding-bottom: 72px;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      position: absolute;
      top: ac(-151px, -90px);
      right: ac(-121px, -90px);
      z-index: -1;

      @media (min-width: 640px) {
        .aura {
          --size: 900px;
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(17, 246, 238, 0.4) 0%,
            rgba(225, 17, 253, 0) 100%
          );
        }
      }
    }
  }

  &__content {
    width: 100%;
    max-width: ac(1030px, 600px);

    &:not(:last-child) {
      margin-bottom: ac(50px, 24px);
    }
  }

  &__text {
    span {
      position: relative;
      z-index: 1;

      &:before {
        position: absolute;
        inset: -4px;
        background: var(--cl-pink);
        transform: rotate(-4.32deg);
        content: '';
        z-index: -1;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    &[data-view] {
      span {
        &:before {
          transform: rotate(0deg);
          opacity: 0;
          transition: 0.4s;
        }
      }

      &.animated {
        span {
          &:before {
            transform: rotate(-4.32deg);
            opacity: 1;
          }
        }
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .text-decor-section__content {
      max-width: unset;
      width: 65.86%;
    }

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 24px;

      .text-decor-section__content {
        width: 100%;
      }
    }
  }

  &__decor {
    position: relative;
    width: 31.72%;
    height: ac(97px, 48px);

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      object-position: right;
      height: 100%;
      max-width: unset;
    }

    @media (max-width: 640px) {
      width: 100%;
      img {
        right: -65px;
      }
    }
  }

  &:not(.hiring-page) {
    .text-decor-section {
      &__wrap {
        z-index: 2;
      }
    }
  }

  &.hiring-page {
    padding-top: ac(0px, 20px);
    padding-bottom: ac(48px, 32px);

    .text-decor-section {
      &__wrap {
        .aura-container {
          &:nth-child(1) {
            position: absolute;
            top: ac(-451px, -90px);
            right: auto;
            left: ac(0px, -100px);
            z-index: -1;

            @media (min-width: 640px) {
              .aura {
                --size: ac(863px, 400px);
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(17, 246, 238, 0.5) 0%,
                  rgba(17, 246, 238, 0) 100%
                );
              }
            }
          }

          &:nth-child(2) {
            position: absolute;
            top: auto;
            bottom: ac(-445px, -90px);
            right: auto;
            left: ac(-203px, -100px);
            z-index: -1;

            @media (min-width: 640px) {
              .aura {
                --size: ac(863px, 300px);
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(225, 17, 253, 0.3) 0%,
                  rgba(225, 17, 253, 0) 100%
                );
              }
            }
          }
        }
      }

      &__content {
        max-width: ac(1010px, 600px);

        &:not(:last-child) {
          margin-bottom: ac(80px, 24px);
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(26px, 22px);
        }
      }

      &__container {
        padding-top: ac(48px, 64px);
        position: relative;

        .text-decor-section__content {
          margin-left: auto;
          max-width: ac(607px, 380px);
        }

        @media (max-width: 640px) {
          padding-top: 0;

          .text-decor-section__content {
            max-width: 100%;
          }
        }
      }

      &__decor-line {
        --arw: 1443;
        --arh: 116;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;

        @media (max-width: 640px) {
          display: none;
        }
      }
    }
  }

  + .services-section {
    margin-top: ac(-72px, -33px);
  }
}
