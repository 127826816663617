@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Unbounded:wght@200..900&display=swap');

:root {
  --cl-black: #080712;
  --cl-black-rgb: 0 0 0;

  --cl-primary: #243865;

  --cl-white: #ffffff;

  --cl-grey: #e0e4e1;

  --cl-medium-blue: #2fb4ff;

  --cl-light-blue: #f9f8fc;

  --cl-blue: #11f6ee;

  --cl-purple: #7f55dc;

  --cl-orange: #ffb546;

  --cl-green: #28c67b;

  --cl-pink: #e111fd;

  --cl-red: #ff1e39;

  --cl-light-grey: #fafafa;

  --cl-dark-grey: #616167;

  --cl-gray-line: #bababa;

  --cl-grey-2: #393941;

  --gradient: linear-gradient(101.59deg, #e111fd 0%, #11f6ee 100%);

  --font-main: 'Outfit', sans-serif;
  --font-second: 'Unbounded', sans-serif;
  --font-md-bt: 'Futura Md BT', sans-serif;
  /*--font-main: 'Gellix', sans-serif;*/

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 12000;

  --vh: calc(100vh / 100);

  --arw: 1;
  --arh: 1;
  --max-line-num: 3;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
