.faq-section {
  padding-top: ac(70px, 32px);
  padding-bottom: ac(38px, 20px);
  overflow: hidden;
  position: relative;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(148px, 90px);
        left: ac(21px, -50px);
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(17, 246, 238, 0.2) 0%,
            rgba(17, 246, 238, 0) 100%
          );
        }
      }

      &:nth-child(2) {
        position: absolute;
        bottom: ac(-61px, -80px);
        right: ac(-101px, -200px);
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(225, 17, 253, 0.21) 0%,
            rgba(225, 17, 253, 0) 100%
          );
        }
      }
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(60px, 28px);
    }

    h2 {
      position: relative;
      text-align: center;
      padding-left: 8px;
      padding-right: 8px;

      &:after {
        position: absolute;
        right: 0;
        transform: translateX(100%) translateY(-50%);
        top: 50%;
        content: '';
        background-image: url('../../assets/static/faq-title-decor.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: ac(61px, 40px);
        width: ac(763px, 509px);
      }

      &:before {
        position: absolute;
        left: 0;
        transform: translateX(-100%) translateY(-50%);
        top: 50%;
        content: '';
        background-image: url('../../assets/static/faq-title-decor.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: ac(61px, 40px);
        width: ac(763px, 509px);
      }
    }
  }

  &__accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: ac(16px, 14px);
    transition: min-height 0.4s ease;
  }

  &__item {
    border-radius: 16px;
    border: 1px solid rgba(17, 246, 238, 0.5);
    padding: ac(35px, 24px) ac(35px, 20px) ac(35px, 24px) ac(47px, 20px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: var(--cl-white);
    transition: background-color 0.4s ease, box-shadow 0.3s ease,
      padding 0.4s ease;

    &:not(.active) {
      background-color: transparent;

      &:hover {
        background-color: rgba(255, 255, 255, 0.07);
      }
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.12);
      padding-top: ac(31px, 24px);
      padding-bottom: ac(55px, 24px);

      .faq-section__item-top {
        margin-bottom: ac(28px, 14px);
      }

      .faq-section__item-btn {
        i {
          transform: rotate(45deg);
        }
      }

      .faq-section__item-content {
        max-height: 1300px;
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;

      &:hover {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      }

      .faq-section__item-content {
        max-height: 0 !important;
      }
    }
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
  }

  &__item-btn {
    --size: ac(48px, 32px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    cursor: pointer;

    i {
      font-size: 26px;
      transition: transform 0.4s ease, color 0.4s ease;
      color: var(--cl-white);
      pointer-events: none;
    }

    &:hover {
      i {
        color: var(--cl-pink);
      }
    }
  }

  &__item-title {
    max-width: calc(100% - 55px);
  }

  &__item-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    /*width: 100%;*/
  }

  &__item-text {
    width: 100%;
    max-width: ac(998px, 800px);

    &.body-text-22 {
      line-height: 1.48;

      p {
        &:not(:last-child) {
          margin-bottom: ac(18px, 14px);
        }
      }
    }
  }
}
