.text-image-section {
  padding-top: ac(99px, 40px);
  padding-bottom: ac(99px, 40px);

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
  }

  &__content {
    width: 52.82%;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__image-container {
    width: 42.19%;
    position: relative;
    z-index: 1;
  }

  .btn {
    min-height: ac(50px, 48px);
  }

  @media (max-width: 640px) {
    .text-image-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 30px;
      }

      &__content {
        width: 100%;
      }

      &__image-container {
        width: 100%;
      }
    }
  }

  &.about-page {
    padding-bottom: 0;

    .aura-container {
      position: absolute;
      top: ac(-430px, -200px);
      left: 0;
      z-index: -1;

      .aura {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(17, 246, 238, 0.5) 0%,
          rgba(17, 246, 238, 0) 100%
        );
      }
    }

    .text-image-section {
      &__image-container {
        --arw: 566;
        --arh: 547.9;

        &::before {
          content: '';
          display: block;
          padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
        }
      }

      &__image-decor {
        position: absolute;
        inset: 0;
        z-index: -1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__image {
        --arw: 526.1;
        --arh: 486.75;
        width: 92.95%;
        mask-image: url('../../assets/static/mask/text-image-about-mask.svg');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        clip-path: inset(1px 1px 1px 1px);
        transform: translateZ(0);
        position: absolute;
        top: 6.81%;
        left: 3.85%;
      }
    }

    @media (min-width: 641px) {
      .text-image-section {
        &__wrap {
          flex-direction: row-reverse;
        }

        &__content {
          width: 48.79%;
        }

        &__image-container {
          width: 45.57%;
        }
      }
    }
  }

  &.candidate-page {
    .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(-430px, -200px);
        left: 0;
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(17, 246, 238, 0.5) 0%,
            rgba(17, 246, 238, 0) 100%
          );
        }
      }

      &:nth-child(2) {
        position: absolute;
        bottom: ac(-632px, -200px);
        left: ac(-203px, 200px);
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(225, 17, 253, 0.3) 0%,
            rgba(225, 17, 253, 0) 100%
          );
        }
      }
    }

    .text-image-section {
      &__image-container {
        border: 1px solid transparent;
        border-radius: ac(24px, 20px);
        overflow: hidden;
      }
      &__image {
        --arw: 656;
        --arh: 438;
        width: 100%;
        border-radius: ac(25px, 21px);
        overflow: hidden;
        clip-path: inset(1px 1px 1px 1px round ac(24px, 20px));

        @media (max-width: 551px) {
          --arw: 1;
          --arh: 1;
        }
      }

      &__title {
        &.heading-40 {
          line-height: 1.32;

          @media (max-width: 551px) {
            font-size: 20px;
          }
        }

        &.title-animation {
          &.animated {
            .splitting .word {
              animation: slide-in 0.3s cubic-bezier(0.5, 0, 0.5, 1) both;
              animation-delay: calc(50ms * var(--word-index));
              /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
            }
          }
        }
      }

      &__wrap {
        @media (max-width: 640px) {
          flex-direction: column-reverse;
        }
      }
    }

    @media (min-width: 641px) {
      .text-image-section {
        &__content {
          width: 42.19%;
        }

        &__image-container {
          width: 52.82%;
        }
      }
    }
  }
}
