.trust-section {
  margin-top: ac(44px, 20px);
  padding-bottom: ac(119px, 40px);
  position: relative;
  z-index: 5;
  &__wrap {
    padding-top: ac(60px, 24px);
    padding-bottom: ac(60px, 40px);
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 56.84%;
    z-index: 2;
    min-height: ac(760px, 500px);
  }

  &__decor {
    --arw: 1566;
    --arh: 760.19;
    position: absolute;
    width: 126.09%;
    left: -15.62%;
    top: 0;
    z-index: -2;

    @media (max-width: 1023px) {
      width: 69%;
      --arw: 852;
      --arh: 761;
      background-image: url('../../assets/static/trust-decor-mob.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      img {
        display: none;
      }
    }
  }

  &__image {
    --arw: 830;
    --arh: 766;
    mask-image: url('../../assets/static/mask/trust-mask.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    width: 66.83%;
    position: absolute;
    top: ac(-6px, -4px);
    left: -15.62%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    @media (min-width: 1380px) {
      padding-top: ac(48px, 0px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(40px, 22px);
    }
  }

  @media (max-width: 890px) {
    .trust-section {
      &__wrap {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 24px;
        padding-left: 0;
        padding-bottom: 0;
        padding-top: 0;
      }

      &__content {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 0;
        width: 100%;
      }

      &__image {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        max-width: 600px;
      }

      &__decor {
        left: 0;
        width: calc(100% + 10px);
        max-width: 618px;
        top: auto;
        bottom: 0;
      }
    }
  }
}
