.media-result-section {
  padding-top: ac(60px, 32px);
  padding-bottom: ac(99px, 32px);
  &__gradient {
    position: absolute;
    --size: ac(1249px, 820px);
    width: var(--size);
    height: var(--size);
    position: absolute;
    border-radius: 100%;
    background: radial-gradient(50% 50% at 50% 50%, rgb(225, 17, 253) 0%, rgba(17, 246, 238, 0) 100%);
    opacity: .3;
    top: 167px;
    right: ac(-416px, -224px);
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      position: absolute;
      bottom: ac(231px, 100px);
      left: ac(-320px, -100px);
      z-index: -1;

      .aura {
        --size: ac(1034px, 500px);
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(17, 246, 238, 0.5) 0%,
          rgba(17, 246, 238, 0) 100%
        );
      }
    }
  }

  &__result {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(40px, 20px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(60px, 24px);
    }

    @media (max-width: 880px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      max-width: 650px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__pagination {
    margin-top: 0;
  }
}
