.process-section {
  padding-top: ac(136px, 32px);
  padding-bottom: ac(99px, 32px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    .aura-container {
      &:nth-child(1) {
        position: absolute;
        top: ac(-415px, -100px);
        left: ac(-132px, -200px);
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(17, 246, 238, 0.5) 0%,
            rgba(17, 246, 238, 0) 100%
          );
        }
      }

      &:nth-child(2) {
        position: absolute;
        bottom: ac(115px, -40px);
        right: ac(52px, -200px);
        z-index: -1;

        .aura {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(225, 17, 253, 0.3) 0%,
            rgba(225, 17, 253, 0) 100%
          );
        }
      }
    }
  }

  &__title {
    width: 100%;
    padding-bottom: ac(27px, 20px);
    border-bottom: 1px solid var(--cl-white);

    h2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(24px, 12px);
    width: 100%;
    white-space: nowrap;
  }

  &__line-decor {
    --arw: 609.87;
    --arh: 48;
    flex: 1;
    max-width: ac(610px, 300px);
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: 8px;
    width: 37.04%;
  }

  &__tab,
  &__mob-tab {
    border-radius: 1000px;
    padding: 10px ac(20px, 16px) 10px ac(26px, 20px);
    min-height: ac(56px, 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    z-index: 1;
    grid-column-gap: 10px;
    cursor: pointer;

    &:before {
      position: absolute;
      z-index: -1;
      background: var(--gradient);
      content: '';
      border-radius: inherit;
      inset: 0;
      opacity: 0;
      transition: 0.3s;
    }

    &:after {
      position: absolute;
      z-index: -2;
      border: 1px solid var(--cl-blue);
      content: '';
      border-radius: inherit;
      inset: 0;
      opacity: 1;
      transition: 0.3s;
    }

    &:hover {
      &:after {
        border-color: var(--cl-pink);
      }
    }

    &.active {
      &:before {
        opacity: 1;
      }

      .process-section {
        &__tab-title {
          color: var(--cl-black);
        }

        &__tab-btn {
          opacity: 0;
        }
      }
    }
  }

  &__mob-tab {
    @media (min-width: 641px) {
      display: none;
    }
  }

  &__tab-title {
    font-size: ac(19px, 17px);
    font-weight: 600;
    line-height: 1.32;
    font-family: var(--font-second);
    transition: 0.3s;
    color: var(--cl-blue);
  }

  &__tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--cl-blue);
    transition: 0.3s;
    min-width: 20px;

    &:hover {
      color: var(--cl-pink);
    }
  }

  &__items {
    position: relative;
    width: 57.33%;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    pointer-events: none;
    mask-image: linear-gradient(
      to bottom,
      rgba(8, 7, 18, 0) 0,
      rgba(8, 7, 18, 1) ac(99px, 40px),
      rgba(8, 7, 18, 1) calc(100% - ac(99px, 40px)),
      rgba(8, 7, 18, 0) 100%
    );

    &.active {
      position: relative;
      top: auto;
      left: auto;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__item-mob-title {
    color: var(--cl-blue);
    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__item-slider {
    width: 100%;
    max-width: 100%;
    padding-top: ac(99px, 40px);
    padding-bottom: ac(99px, 40px);
    height: 561px;
    /*mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) ac(99px, 40px),
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) calc(100% - ac(99px, 40px))
    );*/

    .swiper {
      width: 100%;
      max-width: 100%;
      overflow: visible;
      max-height: 100%;
    }

    .swiper-buttons {
      display: none;
    }
  }

  &__item-slide {
    &:not(:last-child) {
      border-bottom: 1px solid var(--cl-blue);
    }
  }

  &__item-slide-container {
    padding-top: ac(48px, 24px);
    padding-bottom: ac(48px, 24px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(48px, 16px);
    grid-row-gap: ac(32px, 20px);

    @media (max-width: 551px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-title {
    color: var(--cl-blue);

    &:not(:last-child) {
      margin-bottom: ac(24px, 18px);
    }
  }

  @media (max-width: 768px) {
    .process-section {
      &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__tabs {
        display: none;
      }

      &__items {
        width: 100%;
      }

      &__item {
        mask-image: none;
        position: relative;
        top: auto;
        left: auto;
        opacity: 1;
        pointer-events: auto;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &.active {
          .process-section__item-slider {
            opacity: 1;
            margin-bottom: 24px;
            max-height: 2000px;
          }
        }
      }

      &__mob-tab {
        width: 100%;
        display: flex;
      }

      &__item-slider {
        padding-top: 0;
        padding-bottom: 0;
        height: auto !important;
        transition: 0.5s;
        overflow: hidden;
        max-height: 0;
        opacity: 0;

        .swiper-buttons {
          display: flex;
        }
      }
    }
  }
}
