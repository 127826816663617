.case-studies-section {
  padding-top: ac(60px, 32px);
  padding-bottom: ac(70px, 32px);
  --sw-margin: ac(112px, 72px);
  position: relative;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-transform: uppercase;

    &.heading-60 {
      line-height: 1.2;

      @media (max-width: 451px) {
        font-size: 28px;
      }

      @media (max-width: 360px) {
        font-size: 24px;
      }
    }

    h1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .icon-title-decor {
        margin-left: ac(8px, 6px);
      }
    }

    &[data-view] {
      .case-studies__title-line {
        i {
          transform: translateY(-50%) rotate(30deg);
          transition: 0.5s;
          opacity: 0;
        }
      }

      &.animated {
        .case-studies__title-line {
          i {
            transform: translateY(-4px) rotate(0deg);
            opacity: 1;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__title-line {
    white-space: nowrap;
    i {
      color: var(--cl-pink);
      font-size: ac(48px, 22px);

      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      transform: translateY(ac(-3px, -2px));
      margin-right: ac(10px, 8px);

      @media (max-width: 360px) {
        transform: translateY(-1px);
        font-size: 20px;
      }
    }
  }

  &__text {
    width: 100%;
    max-width: ac(786px, 550px);
    margin-left: ac(106px, 0px);
    &:not(:last-child) {
      margin-bottom: var(--sw-margin);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-left: ac(281px, 0px);

    &:before {
      position: absolute;
      z-index: 5;
      content: '';
      background: linear-gradient(
        270deg,
        #080712 0%,
        rgba(8, 7, 18, 0) 20%,
        rgba(8, 7, 18, 0) 50%,
        rgba(8, 7, 18, 0) 80%,
        #080712 100%
      );
      top: ac(-33px, -17px);
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      pointer-events: none;

      @media (max-width: 640px) {
        background: linear-gradient(
          270deg,
          #080712 0%,
          rgba(8, 7, 18, 0) 5%,
          rgba(8, 7, 18, 0) 50%,
          rgba(8, 7, 18, 0) 95%,
          #080712 100%
        );
      }
    }

    .swiper {
      width: calc(100% - 16px);
      max-width: ac(680px, 420px);
      margin-left: 16px;
      overflow: visible;
    }

    .swiper-buttons {
      @media (min-width: 641px) {
        position: absolute;
        right: 0;
        top: calc(var(--sw-margin) * -1);
        transform: translateY(-100%);

        .swiper-button {
          margin-top: 0;
        }
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    background: var(--cl-grey-2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(40px, 24px) ac(40px, 20px);
    border-radius: 16px;
    width: 100%;

    &:not(:last-child) {
      margin-right: ac(62px, 32px) !important;
    }

    &:before {
      position: absolute;
      z-index: 1;
      inset: 0;
      border-radius: inherit;
      background: inherit;
      content: '';
    }
    &.swiper-slide-active {
      .case-studies-section__item-bg {
        transform: scale(1);
        opacity: 1;
      }
      .case-studies-section__item-title {
        color: var(--cl-blue);
      }
    }
  }

  &__item-bg {
    position: absolute;
    top: ac(-32px, -16px);
    left: ac(-32px, -16px);
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
    z-index: -1;
    transform: scale(0.4);
    opacity: 0;
    transition: 0.4s all;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(32px, 14px);
    grid-row-gap: 20px;
    position: relative;
    z-index: 2;

    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }

    @media (max-width: 374px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__item-logo {
    width: ac(219px, 100px);
    min-width: ac(219px, 100px);
    height: ac(123px, 80px);
    padding-left: ac(33px, 10px);
    padding-right: ac(33px, 10px);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: ac(12px, 8px);
    background: var(--cl-white);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .case-studies-section__item-title {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__item-title {
    color: #bababa;
    transition: 0.3s all;
    &.heading-30 {
      @media (max-width: 451px) {
        font-size: 18px;
      }
    }
  }

  &__item-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    overflow: hidden;

    .case-studies-section__item-title {
      position: relative;
      padding-right: ac(16px, 14px);

      &:before {
        position: absolute;
        right: 0;
        transform: translateX(100%) translateY(-50%);
        top: 50%;
        content: '';
        background-image: url('../../assets/static/case-title-decor.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 20px;
        width: 498px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(68px, 24px);
    }
  }

  &__item-text {
    &:not(:last-child) {
      margin-bottom: ac(24px, 18px);
    }
  }

  &__item-buttons {
    position: relative;
    z-index: 2;
  }
}
