.team-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  height: auto;
  border-radius: ac(20px, 16px);
  border: 1px solid var(--cl-white);
  overflow: hidden;
  cursor: pointer;

  &__link {
    position: absolute;
    z-index: 2;
    inset: 0;
  }

  &__bg {
    position: absolute;
    inset: 0;
    border-radius: ac(20px, 16px);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image {
    @mixin aspect-ratio 1, 1.1;
    width: 100%;
    overflow: hidden;
    border-radius: ac(20px, 16px) ac(20px, 16px) 0 0;
    position: relative;
    z-index: 1;
    margin-bottom: -94px;

    img {
      transition: transform 0.3s ease;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    mask-image: url('../../assets/static/mask/team-card-mask.png');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    background: linear-gradient(101.59deg, #e111fd 0%, #11f6ee 100%);
    min-height: 261px;
    padding-top: 27px;
    padding-left: ac(32px, 24px);
    padding-right: ac(32px, 24px);
    padding-bottom: ac(32px, 27px);
    position: relative;
    z-index: 2;
    flex: 1;
  }

  &__title {
    color: var(--cl-black);

    &:not(:last-child) {
      margin-bottom: ac(16px, 12px);
    }

    &.heading-30 {
      line-height: 1.32;
    }
  }

  &__subtitle {
    color: var(--cl-black);
    font-size: ac(22px, 20px);
    font-weight: 400;
    line-height: 1.48;

    &:not(:last-child) {
      margin-bottom: ac(16px, 12px);
    }
  }

  &__buttons {
    margin-top: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
  }

  &__socials {
    position: relative;
    z-index: 10;
    gap: ac(16px, 10px);
    .social-link {
      color: var(--cl-black);

      &:hover {
        color: var(--cl-blue);
      }
    }

    i {
      font-size: ac(48px, 40px);
    }

    .icon-linkedin-card {
      font-size: ac(46px, 38px);
    }
  }

  &__btn {
  }

  &:hover {
    .team-card {
      &__image {
        img {
          @media (min-width: 641px) {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
