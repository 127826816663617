.article-section {
  padding-bottom: ac(102px, 32px);
  padding-top: ac(99px, 40px);
  overflow: visible;
  z-index: 12;
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  &__title {
    color: var(--cl-blue);

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 63.37%;

    @media (max-width: 1279px) {
      width: 60%;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(80px, 32px);
    }
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(48px, 16px);
    grid-row-gap: ac(40px, 18px);

    &:not(:last-child) {
      margin-bottom: ac(80px, 32px);
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__list-subtitle {
    font-family: var(--font-second);
    font-size: ac(18px, 16px);
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.56;
    color: var(--cl-blue);

    &:not(:last-child) {
      margin-bottom: ac(16px, 12px);
    }
  }

  &__aside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(32px, 24px);
    width: 31.72%;

    @media (max-width: 1279px) {
      width: 37%;
    }

    @media (min-width: 1024px) {
      position: sticky;
      top: 100px;
      right: 0;
    }
  }

  &__download {
    width: calc(100% - ac(24px, 16px));
    margin-left: ac(24px, 16px);
    position: relative;
    padding: 24px ac(24px, 20px);
    z-index: 1;
    margin-top: ac(24px, 16px);
    min-height: 209px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:after {
      position: absolute;
      background: var(--cl-black);
      inset: 0;
      border-radius: 16px;
      content: '';
      z-index: -1;
    }
  }

  &__download-title {
    text-align: center;
    width: 100%;
    &.heading-30 {
      line-height: 1.32;

      @media (max-width: 1430px) {
        font-size: ac(28px, 20px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(28px, 24px);
    }
  }

  &__download-buttons {
    width: 100%;

    .btn {
      width: 100%;
      min-height: ac(53px, 50px);
    }
  }

  &__download-decor {
    --arw: 362;
    --arh: 209;
    position: absolute;
    left: ac(-24px, -16px);
    top: ac(-24px, -16px);
    z-index: -2;
    width: 100%;
  }

  &__apply {
    width: calc(100% - ac(24px, 16px));
    margin-left: ac(24px, 16px);
    position: relative;
    padding: 24px ac(24px, 20px);
    z-index: 1;
    margin-top: ac(24px, 16px);
    min-height: ac(276px, 255px, 640);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:after {
      position: absolute;
      background: var(--cl-black);
      inset: 0;
      border-radius: 16px;
      content: '';
      z-index: -1;
    }
  }

  &__apply-title {
    text-align: center;
    width: 100%;
    &.heading-30 {
      line-height: 1.32;

      @media (max-width: 1430px) {
        font-size: ac(28px, 20px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(28px, 24px);
    }
  }

  &__apply-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 16px;

    .btn {
      width: 100%;
      min-height: ac(52px, 50px);
    }
  }

  &__apply-decor {
    --arw: 362;
    --arh: 278;
    position: absolute;
    left: ac(-24px, -16px);
    top: ac(-24px, -16px);
    z-index: -2;
    width: 100%;
  }

  &__team {
    width: 100%;
    .team-card {
      width: 100%;

      &__content {
        position: relative;

        &:after {
          position: absolute;
          inset: 0;
          transform: translateY(8px);
          background-image: url('../../assets/static/mask/team-card-mask.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          content: '';
        }
      }

      &__title {
        position: relative;
        z-index: 5;
        color: var(--cl-white);
      }

      &__subtitle {
        position: relative;
        z-index: 5;
        color: var(--cl-white);
      }

      &__buttons {
        position: relative;
        z-index: 5;
      }

      &__socials {
        .social-link {
          color: var(--cl-white);

          &:hover {
            color: var(--cl-purple);
          }
        }
      }
    }
  }

  &__author {
    position: relative;
    margin-top: ac(24px, 16px);
    margin-left: 16px;
    width: calc(100% - ac(24px, 16px));
    padding: 24px ac(24px, 20px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (min-width: 1025px) {
      margin-left: auto;
    }

    &:after {
      position: absolute;
      background: var(--cl-black);
      inset: 0;
      border-radius: 16px;
      content: '';
      z-index: -1;
    }
  }

  &__author-decor {
    --arw: 370;
    --arh: 177;
    position: absolute;
    left: -16px;
    top: -16px;
    z-index: -2;
    width: 100%;
  }

  &__author-title {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__author-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__author-image-container {
    width: ac(78px, 68px);
    min-width: ac(78px, 68px);
    border: ac(3px, 2px) solid var(--cl-pink);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  &__author-bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__author-image {
    --arw: 1;
    --arh: 1;
    width: 100%;
    border-radius: 50%;
  }

  &__author-info {
    padding-left: ac(17px, 14px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__author-name {
    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(24px, 16px);
  }

  &__share-title {
    color: var(--cl-blue);

    &.heading-20 {
      color: var(--cl-white);
      text-transform: uppercase;
    }
  }

  &__share-socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }

  &__share-title {
    text-transform: none;
  }

  &__share-social {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(48px, 44px);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    color: var(--cl-white);
    transition: 0.3s;

    &:hover {
      background: var(--cl-pink);
      color: var(--cl-blue);
    }
  }

  @media (max-width: 1023px) {
    .article-section {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 32px;
      }

      &__content {
        width: 100%;
      }

      &__aside {
        width: 100%;
        max-width: 400px;
      }
    }
  }

  &.insights-guides-pages {
    padding-top: 0;
    margin-top: ac(-10px, 32px);
  }

  &.video-podcasts-pages {
    margin-top: 0;
  }

  &.job-page {
    padding-bottom: ac(60px, 32px);

    .article-section {
      &__gradient {
        position: absolute;
        right: -22px;
        bottom: ac(204px, 320px, 640);
        --size: ac(863px, 423px);
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgb(17, 246, 238) 0%,
          rgba(225, 17, 253, 0) 100%
        );
        opacity: 0.3;
        width: var(--size);
        height: var(--size);
        border-radius: 100%;
      }
      &__wrap {
        position: relative;
        z-index: 1;

        .aura-container {
          position: absolute;
          bottom: ac(144px, 100px);
          left: ac(-121px, -200px);
          z-index: -1;

          .aura {
            background: radial-gradient(
              50% 50% at 50% 50%,
              rgba(17, 246, 238, 0.4) 0%,
              rgba(225, 17, 253, 0) 100%
            );
          }
        }
      }

      &__title {
        &.heading-40 {
          color: var(--cl-white);
          margin-top: 2px;

          &:not(:last-child) {
            margin-bottom: ac(50px, 28px);
          }
        }

        &.heading-30 {
          color: var(--cl-blue);

          &:not(:last-child) {
            margin-bottom: ac(32px, 24px);
          }
        }
      }

      &__text {
        &.content-element.article-type {
          ul,
          ol {
            + p {
              padding-top: ac(34px, 16px);
            }
          }
        }
      }
    }
    .team-card {
      border-color: var(--cl-blue);
      .link-btn.team-card__btn {
        i {
          background: transparent;
        }
      }
    }
  }
}
